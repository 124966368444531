import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  Alert,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { orderRecipientValidation } from 'newStandard/src/services/orderRecipient/schema';
import AddressFormikFields from 'newStandard/src/components/AddressFormikFields';
import { IOrderRecipient } from 'newStandard/src/services/orderRecipient/types';
import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import MDButton from 'material-ui/components/MDButton';

interface UpsertRecipientModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  recipient?: IOrderRecipient;
}

export default function UpsertRecipientModal({ show, recipient, onClose, onSubmit }: UpsertRecipientModalProps) {
  const params = useParams();
  const orderId = parseInt(params.id || '');

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showHiddenFields, setShowHiddenFields] = useState<boolean>(false);

  const { postOrderRecipient } = useOrderRecipientService();

  const { mutate: postRecipient, isPending } = useMutation({
    mutationFn: postOrderRecipient,
    onSuccess: (data) => {
      if (data.doNotMail) return setShowErrorMessage(true);
      onSubmit();
      onClose();
    },
  });

  const addressFields = [
    { name: 'firstName', label: 'First Name', md: 6 },
    { name: 'lastName', label: 'Last Name', md: 6 },
    { name: 'toOrganization', label: 'Organization (Optional)', md: 12 },
    { name: 'address1', label: 'Address 1', md: 12 },
    { name: 'address2', label: 'Address 2', md: 12 },
    { name: 'city', label: 'City', md: 5 },
  ];

  const optionalFields = [
    { name: 'custom1', label: 'Custom 1' },
    { name: 'custom2', label: 'Custom 2' },
    { name: 'custom3', label: 'Custom 3' },
    { name: 'custom4', label: 'Custom 4' },
    { name: 'custom5', label: 'Custom 5' },
    { name: 'custom6', label: 'Custom 6' },
    { name: 'salutation', label: 'Salutation' },
  ];

  return (
    <Dialog open={show} onClose={() => !isPending && onClose()}>
      <DialogContent>
        <DialogTitle>{recipient ? 'Edit' : 'Add'} Recipient</DialogTitle>
        <Formik
          initialValues={recipient || ({ id: 0, orderId } as IOrderRecipient)}
          validationSchema={orderRecipientValidation}
          onSubmit={(values) => postRecipient(values)}
        >
          <Form>
            <AddressFormikFields fields={addressFields} />
            <FormControlLabel
              label="Show optional fields"
              control={<Checkbox checked={showHiddenFields} onChange={() => setShowHiddenFields((prev) => !prev)} />}
            />
            <Collapse in={showHiddenFields}>
              <Grid container spacing={1}>
                {optionalFields.map((f) => (
                  <Field name={f.name} key={f.name}>
                    {({ field }: FieldProps) => (
                      <Grid item xs={12}>
                        <Typography variant="h5">{f.label}</Typography>
                        <TextField {...field} fullWidth />
                      </Grid>
                    )}
                  </Field>
                ))}
              </Grid>
            </Collapse>
            {showErrorMessage && (
              <Alert onClose={() => setShowErrorMessage(false)} severity="error">
                Recipient exists in the "Do Not Mail" list.
              </Alert>
            )}
            <Stack direction="row" spacing={1} marginTop={2} justifyContent="flex-end">
              <MDButton circular color="light" disabled={isPending} onClick={onClose}>
                Cancel
              </MDButton>
              <MDButton circular type="submit" color="primary" disabled={isPending} sx={{ width: 84 }}>
                {isPending ? <CircularProgress size={18} color="inherit" /> : 'Save'}
              </MDButton>
            </Stack>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
