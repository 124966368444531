import { CreativeEngine } from '@cesdk/cesdk-js';

import { BlockNames, DefaultArtifacts } from './sceneEnums';

const millimeterToPx = (mm: number, dpi: number) => (mm * dpi) / 25.4;
const inchToPx = (inches: number, dpi: number) => inches * dpi;
const transformToPixel = (fromUnit: string, fromValue: number, dpi: number) => {
  if (fromUnit === 'Pixel') return fromValue;
  if (fromUnit === 'Millimeter') return millimeterToPx(fromValue, dpi);
  return inchToPx(fromValue, dpi);
};

// Currently, we are not able to access the original image resolution from the API.
// So we load the image again and fetch the resolution
function fetchImageResolution(url: string): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
    img.onerror = () => reject();
    img.src = url;
  });
}

export async function getImageDpi(engine: CreativeEngine, imageId: number) {
  const imageFill = engine.block.getFill(imageId);
  if (engine.block.getType(imageFill) !== '//ly.img.ubq/fill/image') return;
  const imageUrl = engine.block.getString(imageFill, 'fill/image/imageFileURI') as DefaultArtifacts;

  const scene = engine.block.findByType('scene')[0];
  const pageUnit = engine.block.getEnum(scene, 'scene/designUnit');
  const pageDPI = engine.block.getFloat(scene, 'scene/dpi');

  //Escape if we're dealing with a placeholder image
  const placeholders = [DefaultArtifacts.Rear, DefaultArtifacts.OutsideFront, DefaultArtifacts.Front];
  if (!imageUrl || placeholders.includes(imageUrl)) return pageDPI;

  const frameOriginalWidth = engine.block.getFrameWidth(imageId);
  const frameOriginalHeight = engine.block.getFrameHeight(imageId);
  const framePixelWidth = transformToPixel(pageUnit, frameOriginalWidth, pageDPI);
  const framePixelHeight = transformToPixel(pageUnit, frameOriginalHeight, pageDPI);
  const framePixels = framePixelWidth * framePixelHeight;

  const { width, height } = await fetchImageResolution(imageUrl);
  const imagePixels = width * height;
  return Math.sqrt(imagePixels / framePixels) * pageDPI;
}

export const qrCodeDefaultData = {
  name: BlockNames.QRCode,
  height: 0.8,
  width: 0.8,
  positionX: 0,
  positionY: 0,
};

type FillType = { fillId: number; uri?: never } | { fillId?: never; uri: string };

type ImageDataType = {
  name: BlockNames;
  height: number;
  width: number;
  positionX: number;
  positionY: number;
  parent: number;
} & FillType;

export function createImage(
  engine: CreativeEngine,
  { name, height, width, positionX, positionY, parent, fillId, uri }: ImageDataType
) {
  const block = engine.block.create('graphic');
  engine.block.setName(block, name);
  const rectShape = engine.block.createShape('rect');
  engine.block.setShape(block, rectShape);
  engine.block.setHeightMode(block, 'Absolute');
  engine.block.setHeight(block, height);
  engine.block.setWidthMode(block, 'Absolute');
  engine.block.setWidth(block, width);
  engine.block.setPositionXMode(block, 'Absolute');
  engine.block.setPositionX(block, positionX);
  engine.block.setPositionYMode(block, 'Absolute');
  engine.block.setPositionY(block, positionY);
  engine.block.appendChild(parent, block);
  const imageFill = engine.block.createFill('image');
  if (uri) engine.block.setString(imageFill, 'fill/image/imageFileURI', uri);
  engine.block.setFill(block, fillId ?? imageFill);
  engine.block.setKind(block, 'image');
  return block;
}

export function recreateImage(engine: CreativeEngine, block: number) {
  const imageData = {
    name: engine.block.getName(block) as BlockNames,
    height: engine.block.getHeight(block),
    width: engine.block.getWidth(block),
    positionX: engine.block.getPositionX(block),
    positionY: engine.block.getPositionY(block),
    parent: engine.block.getParent(block),
    fillId: engine.block.getFill(block),
  };
  const newBlock = createImage(engine, imageData);
  engine.block.setScopeEnabled(block, 'lifecycle/destroy', true);
  engine.block.destroy(block);
  return newBlock;
}
