import { CreativeEngine } from '@cesdk/cesdk-js';
import { useMutation } from '@tanstack/react-query';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions } from 'newStandard/src/services/order/types';
import { OrderParameter } from 'models/orderParameter';
import { useGlobal } from 'context/global-context';
import OrderService from 'services/order';

import { KeepOutColor, setKeepOutBlockColor, setWhiteOutBlockColor } from '../utils/keepOutHelper';
import { checkHasEnvelope, checkIsPrintedCard } from '../utils/templateHelper';
import { ArtifactType, IArtifact } from '../services/artifact/types';
import { MailMergeMapType } from '../utils/mailMergeOptions';
import useSceneService from '../services/sceneService';
import { BlockNames } from '../utils/sceneEnums';

export function useSaveScene(
  engine: CreativeEngine,
  envelopeEngine: CreativeEngine,
  mailMergeFields: MailMergeMapType
) {
  const { setShowLoader } = useGlobal();
  const { uploadScene } = useSceneService();
  const { postOrderParameters } = OrderService();
  const { template, setTemplate, nextStep, changeStep } = useWorkflowContext();

  const { mutate: saveMailMergeFields } = useMutation({
    mutationFn: (parameters: OrderParameter[]) => postOrderParameters(template.id, parameters),
  });

  const saveSceneCallback = async (artifacts: IArtifact[], shouldSaveEnvelope: boolean = false) => {
    if (shouldSaveEnvelope) {
      setTemplate((prev) => ({ ...prev, orderArtifacts: artifacts }));
      const formData = new FormData();
      formData.append('scene', await envelopeEngine.scene.saveToString());
      saveScene({ id: template.id, sceneType: ArtifactType.EnvelopeScene, formData });
    } else {
      changeStep(nextStep ?? 3, { ...template, orderArtifacts: artifacts });
      setShowLoader(false);
    }
  };

  const { mutate: saveScene } = useMutation({
    mutationFn: uploadScene,
    onSuccess: (savedArtifact) => {
      const shouldSaveEnvelope =
        savedArtifact.artifactType === ArtifactType.CreativeEditorScene && checkHasEnvelope(template.product);
      if (!template.orderArtifacts) return saveSceneCallback([savedArtifact], shouldSaveEnvelope);
      const artifacts = [...template.orderArtifacts];
      const artifactIndex = artifacts.findIndex((artifact) => artifact.id === savedArtifact.id);
      if (artifactIndex > 0) artifacts[artifactIndex] = savedArtifact;
      else artifacts.push(savedArtifact);
      saveSceneCallback(artifacts, shouldSaveEnvelope);
    },
  });

  const saveQrCodeProperties = () => {
    setTemplate((prev) => {
      if (!prev.qrUrl || !engine) return prev;
      const auxTemplate = { ...prev };
      const qrCodeBlock = engine.block.findByName(BlockNames.QRCode)[0];
      auxTemplate.qrAngle = engine.block.getRotation(qrCodeBlock);
      auxTemplate.qrCodeX = engine.block.getPositionX(qrCodeBlock);
      auxTemplate.qrCodeY = engine.block.getPositionY(qrCodeBlock);
      auxTemplate.qrCodeHeight = engine.block.getHeight(qrCodeBlock);
      auxTemplate.qrCodeWidth = auxTemplate.qrCodeHeight;
      return auxTemplate;
    });
  };

  const makeKeepOutWhite = () => {
    if (!engine) return;
    if (checkIsPrintedCard(template.product)) {
      setWhiteOutBlockColor(engine, KeepOutColor.WHITE);
    } else if (template.product === ProductOptions.HandwrittenPostCardA8) {
      setKeepOutBlockColor(engine, KeepOutColor.WHITE, BlockNames.HWPCKeepOutZone);
    } else if (template.product === ProductOptions.HandwrittenBiFoldCard) {
      setKeepOutBlockColor(engine, KeepOutColor.WHITE, BlockNames.OutsideKeepOutArea);
    }
  };

  const mailMergeToParameter = (): OrderParameter[] => {
    const mailMergeArray = [...(mailMergeFields?.entries() ?? [])];
    return mailMergeArray.map((field) => ({ id: 0, parameterType: field[0], parameterText: field[1] }));
  };

  const saveCurrentScene = async () => {
    if (!engine) return;
    saveMailMergeFields(mailMergeToParameter());
    setShowLoader(true);
    saveQrCodeProperties();
    makeKeepOutWhite();
    const formData = new FormData();
    formData.append('scene', await engine.scene.saveToString());
    saveScene({ id: template.id, sceneType: ArtifactType.CreativeEditorScene, formData });
  };

  return { saveCurrentScene };
}
