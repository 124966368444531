import { useSuspenseQuery } from '@tanstack/react-query';
import { Box, MenuItem, Select, TextField, Typography } from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import IncrementDecrementButton from 'components/increment-decrement-button';
import useFontService from 'newStandard/src/services/font';

import { useEditorContext } from '../../contexts/useEditorContext';
import useHandwrittenText from '../../hooks/useHandwrittenText';

const DEFAULT_FONT_SIZE = 18;

export default function HandwritingStyle() {
  const { engine } = useEditorContext();
  const { getFonts } = useFontService();
  const { selectedFont } = useHandwrittenText();
  const { template, setTemplate } = useWorkflowContext();

  const { data: fonts } = useSuspenseQuery({ queryKey: ['getFonts'], queryFn: getFonts });

  const renderLabel = (value: string) => {
    if (!fonts || !selectedFont) return value;
    return (
      <Typography fontFamily={selectedFont.label} lineHeight={1}>
        {selectedFont.label}
      </Typography>
    );
  };

  const renderAdornment = (type: 'minus' | 'plus') => (
    <IncrementDecrementButton
      type={type}
      value={template.fontSize || DEFAULT_FONT_SIZE}
      minValue={17}
      maxValue={25}
      setValue={(fontSize) => setTemplate((prev) => ({ ...prev, fontSize }))}
    />
  );

  return (
    <Box>
      <Typography variant="body2" fontWeight={'bold'} mb={1}>
        Handwriting Style
      </Typography>
      <Box display="flex" gap={1}>
        <Select
          fullWidth
          value={template.font || fonts[0].label}
          onOpen={() => engine?.editor.setEditMode('Transform')}
          onChange={(event) => setTemplate((prev) => ({ ...prev, font: event.target.value }))}
          renderValue={renderLabel}
        >
          {fonts.map((font) => (
            <MenuItem key={font.id} value={font.label}>
              <Typography fontFamily={font.label}>
                {font.label} - The quick brown fox jumped over the lazy fox
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <TextField
          value={template.fontSize || DEFAULT_FONT_SIZE}
          sx={{ width: 160 }}
          InputProps={{ startAdornment: renderAdornment('minus'), endAdornment: renderAdornment('plus') }}
          inputProps={{ disabled: true, sx: { textAlign: 'center', pr: 0 } }}
        />
      </Box>
    </Box>
  );
}

HandwritingStyle.Skeleton = function HandwritingStyleSkeleton() {
  return (
    <Box>
      <Typography variant="body2" fontWeight={'bold'}>
        Handwriting Style
      </Typography>
      <Box display="flex" gap={1}>
        <Select fullWidth disabled>
          <MenuItem value="">
            <Typography>Loading...</Typography>
          </MenuItem>
        </Select>
        <TextField value="" disabled sx={{ width: 160 }} />
      </Box>
    </Box>
  );
};
