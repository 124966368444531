export enum OrderRecipientWarning {
  MissingMailMergeField,
  PotentiallyUndeliverable,
  Undeliverable,
}

export enum OrderRecipientError {
  MissingAddressFields,
  MissingNameFields,
  NonUsAddress,
}

export interface IOrderRecipient {
  id: number;
  orderId: number;
  salutation: string;
  firstName: string;
  lastName: string;
  toOrganization: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  radiusPinStreet?: string;
  radiusPinAddress?: string;
  errors?: OrderRecipientError[];
  warnings?: OrderRecipientWarning[];
  missingMailMergeFields?: string[];
  isDeliverable?: boolean;
}

export interface IOrderRecipientData {
  recipients: IOrderRecipient[];
  usedColumns: string[];
  hasUnprocessedRecipients: boolean;
  totalCount: number;
}

export interface IOrderRecipientFilterParams {
  page: number;
  pageSize: number;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  search: string;
  showWarning: boolean;
  showError: boolean;
}

export interface IOrderRecipientWarnings {
  missingMailMergeField: number;
  potentiallyUndeliverable: number;
  undeliverable: number;
}

export interface IOrderRecipientErrors {
  missingAddressFields: number;
  missingNameFields: number;
  nonUsAddress: number;
}

export interface IOrderRecipientIssues {
  recipientWarningDetails: IOrderRecipientWarnings;
  recipientErrorDetails: IOrderRecipientErrors;
}
