import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import ReviewProofs from 'newStandard/src/components/ReviewProofs';
import MDButton from 'material-ui/components/MDButton';
import MDCard from 'material-ui/components/MDCard';

export default function ReviewStep() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { changeStep } = useWorkflowContext();
  const [isProofApproved, setIsProofApproved] = useState<boolean>(false);

  const handleSave = () => {
    queryClient.invalidateQueries({ queryKey: ['templates'] });
    navigate('/my-templates');
  };

  return (
    <MDCard sx={{ p: 2 }}>
      <Stack spacing={2}>
        <ReviewProofs isProofApproved={isProofApproved} setIsProofApproved={setIsProofApproved} />
        <Box display={'flex'} justifyContent={'space-between'} mt={2}>
          <MDButton color="light" onClick={() => changeStep(2)} sx={{ width: 100 }}>
            Back
          </MDButton>
          <MDButton disabled={!isProofApproved} sx={{ width: 100 }} onClick={handleSave}>
            Save
          </MDButton>
        </Box>
      </Stack>
    </MDCard>
  );
}
