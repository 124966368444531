import truncate from "lodash/truncate"
import {SceneDefaultArtifacts} from "models/enums/placeholderImage"
import {SceneBlockNames} from "models/enums/SceneBlockNames"
import logger from "loglevel";
import { ProductTypes } from "models/enums/ProductTypes";
import { BlockNames, DefaultArtifacts } from "newStandard/src/features/Editor/utils/sceneEnums";

// Calculates the overlap of two elements
// Returns the overlap as percentage of the first element
const getElementOverlap = ([aX1, aY1, aX2, aY2], [bX1, bY1, bX2, bY2]) => {
    return (
        (Math.max(0, Math.min(aX2, bX2) - Math.max(aX1, bX1)) *
            Math.max(0, Math.min(aY2, bY2) - Math.max(aY1, bY1))) /
        ((aX2 - aX1) * (aY2 - aY1))
    )
}

const getElementBoundingBox = (engine, blockId) => {
    const [x, y, width, height] = [
        engine.block.getGlobalBoundingBoxX(blockId),
        engine.block.getGlobalBoundingBoxY(blockId),
        engine.block.getGlobalBoundingBoxWidth(blockId),
        engine.block.getGlobalBoundingBoxHeight(blockId),
    ]
    return [x, y, x + width, y + height]
}

const getRelevantBlocks = (engine) => {
    return [
        ...engine.block.findByType("text"),
        ...engine.block.findByType("image"),
        ...engine.block.findByType("sticker"),
        ...engine.block.findByType("shapes/rect"),
        ...engine.block.findByType("shapes/line"),
        ...engine.block.findByType("shapes/star"),
        ...engine.block.findByType("shapes/polygon"),
        ...engine.block.findByType("shapes/ellipse"),
    ]
}

export const getProtrudingBlocks = (engine) => {
    const page = engine.block.findByType("page")[0]
    return getRelevantBlocks(engine)
        .map((elementBlockId) => {
            const overlapWithPage = getElementOverlap(
                getElementBoundingBox(engine, elementBlockId),
                getElementBoundingBox(engine, page)
            )
            const isProtruding = overlapWithPage > 0 && overlapWithPage < 0.99
            return isProtruding && elementBlockId
        })
        .filter((o) => !!o)
}

export const getOutsideBlocks = (engine) => {
    const page = engine.block.findByType("page")[0]
    return getRelevantBlocks(engine)
        .map((elementBlockId) => {
            const overlapWithPage = getElementOverlap(
                getElementBoundingBox(engine, elementBlockId),
                getElementBoundingBox(engine, page)
            )
            const isOutside = overlapWithPage === 0
            return isOutside && elementBlockId
        })
        .filter((o) => !!o)
}

// Returns the BlockIds of all blocks that lay "above" the block
export const getBlockIdsAbove = (engine, blockId) => {
    const page = engine.block.findByType("page")[0]
    const sortedBlockIds = engine.block.getChildren(page)
    return sortedBlockIds.slice(sortedBlockIds.indexOf(blockId) + 1)
}

// Returns all text blocks that may obstructed by other blocks
export const getPartiallyHiddenTexts = (engine) => {
    return engine.block
        .findByType("text")
        .map((elementBlockId) => {
            const elementsLayingAbove = getBlockIdsAbove(engine, elementBlockId)
            const anyElementOverlapping = elementsLayingAbove.some(
                (blockId) =>
                    getElementOverlap(
                        getElementBoundingBox(engine, elementBlockId),
                        getElementBoundingBox(engine, blockId)
                    ) > 0
            )
            return anyElementOverlapping && elementBlockId
        })
        .filter((o) => !!o)
}

export const selectAllBlocks = (engine, blockIds) => {
    engine.block
        .findAllSelected()
        .forEach((block) => engine.block.setSelected(block, false))
    blockIds.forEach((block) => engine.block.setSelected(block, true))
    return blockIds
}

export const transformToPixel = (fromUnit, fromValue, dpi) => {
    if (fromUnit === "Pixel") {
        return fromValue
    }
    if (fromUnit === "Millimeter") {
        return millimeterToPx(fromValue, dpi)
    } else {
        return inchToPx(fromValue, dpi)
    }
}

export const transformFromPixel = (toUnit, fromValue, dpi) => {
    if (toUnit === "Pixel") {
        return fromValue
    }
    if (toUnit === "Millimeter") {
        return pxToMillimeter(fromValue, dpi)
    } else {
        return pxToInch(fromValue, dpi)
    }
}

export const pxToMillimeter = (px, dpi) => (px * 25.4) / dpi
export const millimeterToPx = (mm, dpi) => (mm * dpi) / 25.4
export const pxToInch = (px, dpi) => px / dpi
export const inchToPx = (inches, dpi) => inches * dpi

// Removes blank space from the image
export const reshapeBlockToImage = async (
    engine,
    frameWidth,
    frameHeight,
    imageId
) => {
    let imageFill = engine.block.getFill(imageId)
    let existingUrl = engine.block.getString(imageFill, "fill/image/imageFileURI")

    const {width, height} = await fetchImageResolution(existingUrl)

    var imageAspectRatio = width / height
    var frameAspectRatio = frameWidth / frameHeight
    var ratioOfRatio = imageAspectRatio / frameAspectRatio

    // image is wider, scale by width
    if (imageAspectRatio < frameAspectRatio) {
        var currentXPos = engine.block.getPositionX(imageId)
        var newWidth = frameWidth * ratioOfRatio
        var shiftInX = (frameWidth - newWidth) / 2

        engine.block.setWidth(imageId, newWidth)
        engine.block.setPositionX(imageId, currentXPos + shiftInX)
    } else {
        var currentYPos = engine.block.getPositionY(imageId)
        var newHeight = frameHeight / ratioOfRatio
        var shiftInY = (frameHeight - newHeight) / 2

        engine.block.setHeight(imageId, newHeight)
        engine.block.setPositionY(imageId, currentYPos + shiftInY)
    }
}

// A number above 1 means that the image is of acceptable quality
export const getImageDpi = async (engine, imageId) => {
    let imageFill = engine.block.getFill(imageId)
    let imageUrl = engine.block.getString(imageFill, "fill/image/imageFileURI")

    const scene = engine.block.findByType("scene")[0]
    const [pageUnit, pageDPI] = [
        engine.block.getEnum(scene, "scene/designUnit"),
        engine.block.getFloat(scene, "scene/dpi"),
    ]
    //Escape if we're dealing with a placeholder image
    if (
        !imageUrl ||
        imageUrl === SceneDefaultArtifacts.Rear ||
        imageUrl === SceneDefaultArtifacts.OutsideFront ||
        imageUrl === SceneDefaultArtifacts.Front ||
        imageUrl === ''
    ) {
        return pageDPI
    }
    const [frameWidthDesignUnit, frameHeightDesignUnit] = [
        engine.block.getFrameWidth(imageId),
        engine.block.getFrameHeight(imageId),
    ]

    const [frameWidth, frameHeight] = [
        transformToPixel(pageUnit, frameWidthDesignUnit, pageDPI),
        transformToPixel(pageUnit, frameHeightDesignUnit, pageDPI),
    ]

    var framePixels = frameWidth * frameHeight
    const {width, height} = await fetchImageResolution(imageUrl)
    var imagePixels = width * height
    var imageDpi = Math.sqrt(imagePixels / framePixels) * pageDPI

    return imageDpi
}

// A number above 1 means that the image is of acceptable quality
export const getImageDpiByName = async (engine, blockName) => {
    var blocks = engine.block.findByName(blockName)
    if (blocks.length === 1) {
        return await getImageDpi(engine, blocks[0])
    }
}

// Poor man's cache
const resolutionCache = {}

// Currently, we are not able to access the original image resolution from the API.
// So we load the image again and fetch the resolution
export const fetchImageResolution = (url) => {
    return new Promise((resolve, reject) => {
        if (resolutionCache[url]) {
            resolve(resolutionCache[url])
            return
        }
        let img = new Image()
        img.onload = () => {
            const imageResolution = {
                width: img.naturalWidth,
                height: img.naturalHeight,
            }
            resolutionCache[url] = imageResolution
            resolve(imageResolution)
        }
        img.onerror = () => reject()
        img.src = url
    })
}

// Outputs the pixel density of the image in the frame.
// An output of 1 means, one pixel in the image can be rendered into the frame.
// An output of 0.5 means that one pixel in the image will be rendered as 2 pixel into the frame, leading to a loss of quality.
export const getImageQuality = (
    imageWidth,
    imageHeight,
    frameHeight,
    frameWidth,
    scale = 1,
    mode = "cover"
) => {
    var originalRatios = {
        width: frameWidth / (imageWidth / scale),
        height: frameHeight / (imageHeight / scale),
    }
    var coverRatio = Math.max(originalRatios.width, originalRatios.height)
    return 1 / coverRatio
}

export const getImageLayerName = (engine, blockId) => {
    const layerName = engine.block.getName(blockId)
    if (layerName && !["Text"].includes(layerName)) {
        return layerName
    }
    const type = engine.block.getType(blockId)
    switch (type) {
        case "//ly.img.ubq/text":
            const textContent = engine.block.getString(blockId, "text/text")
            const truncatedTextContent = truncate(textContent, {length: 25})
            if (truncatedTextContent) {
                return truncatedTextContent
            }
            return "Text"
        case "//ly.img.ubq/graphic":
            return "Image"
        case "//ly.img.ubq/sticker":
            return "Sticker"
        case "//ly.img.ubq/shapes/rect":
            return "Shape: rect"
        case "//ly.img.ubq/shapes/line":
            return "Shape: line"
        case "//ly.img.ubq/shapes/star":
            return "Shape: star"
        case "//ly.img.ubq/shapes/polygon":
            return "Shape: polygon"
        case "//ly.img.ubq/shapes/ellipse":
            return "Shape: ellipse"
        default:
            return type
    }
}

export const setTextSizeByBlockName = (creativeEngine, blockName, size) => {
    if (!creativeEngine) {
        return
    }

    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.setFloat(blockId, "text/fontSize", size)
    })
}

export const setParagraphSpacingByBlockName = (
    creativeEngine,
    blockName,
    paragraphSpacing
) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.setFloat(blockId, "text/paragraphSpacing", paragraphSpacing)
    })
}

export const setLineHeightByBlockName = (
    creativeEngine,
    blockName,
    paragraphSpacing
) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.setFloat(blockId, "text/lineHeight", paragraphSpacing)
    })
}

export const setTextByBlockName = (creativeEngine, blockName, text) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.setString(blockId, "text/text", text)
    })
}

export const setBlockHeightByName = (creativeEngine, blockName, height) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.setHeight(blockId, height)
    })
}

export const getBlockHeightByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    var retVal = null
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        retVal = creativeEngine.block.getHeight(blockId)
    })
    return retVal
}

export const getBlockWidthByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    var retVal = null
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        retVal = creativeEngine.block.getWidth(blockId)
    })
    return retVal
}


export const getBlockXPositionByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    var retVal = null
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        retVal = creativeEngine.block.getPositionX(blockId)
    })
    return retVal
}

export const getBlockYPositionByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    var retVal = null
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        retVal = creativeEngine.block.getPositionY(blockId)
    })
    return retVal
}

export const getBlockRotationByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    var retVal = null
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        retVal = creativeEngine.block.getRotation(blockId)
    })

    //Convert retval from radians to degrees
    retVal = (retVal * 180) / Math.PI
    return retVal
}

export const setBlockToImageByName = (creativeEngine, blockName, url) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        setBlockToImageById(creativeEngine, blockId, url)
    })
}

export const setBlockToImageById = (creativeEngine, blockId, url) => {
    if (!creativeEngine) {
        return
    }

    let imageFill = creativeEngine.block.getFill(blockId)
    const isValid = creativeEngine.block.isValid(imageFill);
    if(!isValid) imageFill = creativeEngine.block.createFill('image');
    let existingUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI");

    if (existingUrl !== url) {
        creativeEngine.block.setString(imageFill, "fill/image/imageFileURI", url)
        creativeEngine.block.setPlaceholderControlsOverlayEnabled(blockId, true)
        creativeEngine.block.setPlaceholderControlsButtonEnabled(blockId, false)
        creativeEngine.block.setPlaceholderEnabled(blockId, false)

        // Fits the image to the block - scaling it to the height of the block
        // Documentation: https://img.ly/docs/cesdk/engine/api/block-crop/?platform=node#crop
        creativeEngine.block.setContentFillMode(blockId, "Contain")
    }
}

export const sendBlockToBackByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }
    creativeEngine.block.findByName(blockName).forEach((blockId) => {
        creativeEngine.block.sendToBack(blockId)
    })
}

export const getTextByBlockName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }

    var blocks = creativeEngine.block.findByName(blockName)
    if (blocks.length === 1) {
        return creativeEngine.block.getString(blocks[0], "text/text")
    }
    return null
}

export const insertOrReplaceTextAtCursor = (creativeEngine, blockName, textToInsert, selectedRange) => {
    if (!creativeEngine) {
        return
    }

    var blocks = creativeEngine.block.findByName(blockName)
    if (blocks.length === 1) {
        if (selectedRange && selectedRange.from >= 0 && selectedRange.to >= 0) {
            creativeEngine.block.replaceText(blocks[0], textToInsert, selectedRange.from, selectedRange.to)
        } else {
            creativeEngine.block.replaceText(blocks[0], textToInsert, 0, 0)
        }
    }
    return null
}


//Shows or hides blocks. If urlToMatchOn is set, only blocks NOT MATCHING with the given url will be affected
export const showBlockByName = (
    creativeEngine,
    blockName,
    show,
    urlToMatchOn = null
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        var doId = true
        if (urlToMatchOn) {
            let imageFill = creativeEngine.block.getFill(block)
            let imgUrl = creativeEngine.block.getString(imageFill, "fill/image/imageFileURI")

            if (imgUrl !== urlToMatchOn) {
                doId = false
            }
        }
        if (doId) {
            creativeEngine.block.setBool(block, "visible", show)
        }
    })
}

//Shows or hides blocks. If urlToMatchOn is set, only blocks NOT MATCHING with the given url will be affected
export const setYPositionByName = (creativeEngine, blockName, yPosition) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setPositionY(block, yPosition)
    })
}

//Gets the current block, removes it and adds another, movable one
export const setCanBeMovedAroundByName = (
    creativeEngine,
    blockName,
    canBeArranged
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setScopeEnabled(block, "design/arrange/move", canBeArranged)
    })
}



export const removeBlockByName = (creativeEngine, blockName) => {
    if (!creativeEngine) {
        return
    }

    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        removeBlock(creativeEngine, block)
    })
}

export const removeBlock = (creativeEngine, blockId) => {
    if (!creativeEngine) {
        return
    }

    try{
        creativeEngine.block.setScopeEnabled(blockId, 'lifecycle/destroy', true);
        creativeEngine.block.destroy(blockId);
    }
    catch(ex){
    }
}

export const showPlaceHolderButtonByName = (
    creativeEngine,
    blockName,
    show
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setBool(block, "placeholderControls/showButton", show)
    })
}

export const moveBlockToBottomRightByName = (
    creativeEngine,
    blockName,
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setPositionX(block, 1.16)
        creativeEngine.block.setPositionY(block, 1.16)
    })
}

export const setIsAlwaysOnTopByName = (
    creativeEngine,
    blockName,
    isAlwaysOnTop
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)
    blocks.forEach((block) => {
        creativeEngine.block.setAlwaysOnTop(block, true)
    })
}

export const showPlaceHolderOverlayByName = (
    creativeEngine,
    blockName,
    show
) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setBool(block, "placeholderControls/showOverlay", show)
    })
}

//Sets height by name of block
export const setHeightByName = (creativeEngine, blockName, height) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setHeight(block, height)
    })
}

//Sets width by name of block
export const setWidthByName = (creativeEngine, blockName, width) => {
    if (!creativeEngine) {
        return
    }
    var blocks = creativeEngine.block.findByName(blockName)

    blocks.forEach((block) => {
        creativeEngine.block.setWidth(block, width)
    })
}
export const addTrackablePhoneNumber = (creativeEngine, currentPageBlockId, value) => {
    const block = creativeEngine.block.create("text")

    creativeEngine.block.setFloat(block, "text/fontSize", 20)
    creativeEngine.block.setHeightMode(block, "Auto")
    creativeEngine.block.replaceText(block, value)

    creativeEngine.block.setPlaceholderEnabled(block, false)

    const pageWidth = creativeEngine.block.getWidth(currentPageBlockId)
    creativeEngine.block.setWidth(block, pageWidth * 0.5)
    autoPlaceBlockOnPage(creativeEngine, currentPageBlockId, block)
}

// export const addText = (creativeEngine, fontFileUri, currentPageBlockId) => {
export const addText = (creativeEngine, currentPageBlockId) => {
    const block = creativeEngine.block.create("text")
    const currentTypeface = creativeEngine.block.getTypeface(block);
    creativeEngine.block.setFont(block, "extensions/ly.img.cesdk.fonts/fonts/Roboto/Roboto-Regular.ttf", currentTypeface);
    creativeEngine.block.setFloat(block, "text/fontSize", 40)
    creativeEngine.block.setEnum(block, "text/horizontalAlignment", "Center")
    creativeEngine.block.setHeightMode(block, "Auto")
    creativeEngine.block.setName(block, SceneBlockNames.PrintedText)

    const pageWidth = creativeEngine.block.getWidth(currentPageBlockId)
    creativeEngine.block.setWidth(block, pageWidth * 0.5)
    autoPlaceBlockOnPage(creativeEngine, currentPageBlockId, block)
}

export const addImage = (engine, parentId, blockName= SceneBlockNames.Image) => {
    const block = engine.block.create('graphic');
    const rectShape = engine.block.createShape('rect');
    const imageFill = engine.block.createFill('image');

    engine.block.setShape(block, rectShape);
    engine.block.setFill(block, imageFill);
    engine.block.setKind(block, 'image');

    engine.block.setBool(block, "placeholderControls/showButton", false)
    engine.block.setBool(block, "placeholderControls/showOverlay", false)
    engine.block.setName(block, blockName)
    var scaleFactor = 0.5
    const [parentWidth, parentHeight] = [
        engine.block.getWidth(parentId) * scaleFactor,
        engine.block.getHeight(parentId) * scaleFactor,
    ]

    engine.block.setHeightMode(block, "Absolute")
    engine.block.setHeight(block, parentWidth)
    engine.block.setWidthMode(block, "Absolute")
    engine.block.setWidth(block, parentHeight)

    autoPlaceBlockOnPage(engine, parentId, block)

    return block
}

export const addImagePlaceholder = function(engine, parentId, positionX, positionY, width, height, blockName = BlockNames.Image, sceneDefaultImage = DefaultArtifacts.OutsideFront) {
    if (!engine) {
        return;
    }

    try {
        const block = engine.block.create("graphic")
        const rectShape = engine.block.createShape('rect');
        const imageFill = engine.block.createFill('image');
        engine.block.setString(imageFill, "fill/image/imageFileURI", sceneDefaultImage)
        
        engine.block.setBool(block, "placeholderControls/showButton", true)
        engine.block.setBool(block, "placeholderControls/showOverlay", true)
        engine.block.setBool(block, "placeholder/enabled", true)

        engine.block.setName(block, blockName)
        
        engine.block.setPositionX(block, positionX)
        engine.block.setPositionY(block, positionY)
        engine.block.setHeightMode(block, "Absolute")
        engine.block.setHeight(block, height)
        engine.block.setWidthMode(block, "Absolute")
        engine.block.setWidth(block, width)
      
        engine.block.setShape(block, rectShape);
        engine.block.setFill(block, imageFill);
        engine.block.setKind(block, 'image/placeholder');
        engine.block.appendChild(parentId, block)

        console.log('Image placeholder added successfully');
    } catch (error) {
        console.error('Error adding image placeholder:', error);
    }
}

export const addKeepOutArea = function (engine, parentId, positionX, positionY, width, height, blockName) {
    if (!engine) return;

    try {
        const block = engine.block.create("graphic")
        engine.block.setName(block, blockName)
        // shape
        const rectShape = engine.block.createShape('rect');
        engine.block.setShape(block, rectShape);
        // fill
        const solidColor = engine.block.createFill('color');
        engine.block.setFill(block, solidColor);
        engine.block.setFillEnabled(block, true);
        engine.block.setColor(block, 'fill/solid/color', { r: 1, g: 1, b: 1, a: 1 });
        // placeholder
        engine.block.setBool(block, "placeholderControls/showButton", false)
        engine.block.setBool(block, "placeholderControls/showOverlay", false)
        engine.block.setBool(block, "placeholder/enabled", false)
        // Other properties
        engine.block.setAlwaysOnTop(block, true)
        engine.block.setPositionX(block, positionX)
        engine.block.setPositionY(block, positionY)
        engine.block.setHeightMode(block, "Absolute")
        engine.block.setHeight(block, height)
        engine.block.setWidthMode(block, "Absolute")
        engine.block.setWidth(block, width)
        // prevent select
        engine.block.setScopeEnabled(block, "editor/select", false)
        // Append the block to parent
        engine.block.appendChild(parentId, block)

        // printPropertValuesForBlock(creativeEngine, keepoutZone, SceneBlockNames.HWPCKeepoutZone)
        console.log('Keepout area with text added successfully');
    } catch (error) {
        console.error('Error adding keepout area:', error);
    }
}

// Appends a block into the scene and positions it somewhat randomly.
const autoPlaceBlockOnPage = (
    engine,
    page,
    block,
    config = {
        basePosX: 0.25,
        basePosY: 0.25,
        randomPosX: 0.05,
        randomPosY: 0.05,
    }
) => {
    engine.block
        .findAllSelected()
        .forEach((blockId) => engine.block.setSelected(blockId, false))
    engine.block.appendChild(page, block)

    const pageWidth = engine.block.getWidth(page)
    const posX = pageWidth * (config.basePosX + Math.random() * config.randomPosX)
    engine.block.setPositionXMode(block, "Absolute")
    engine.block.setPositionX(block, posX)

    const pageHeight = engine.block.getWidth(page)
    const posY =
        pageHeight * (config.basePosY + Math.random() * config.randomPosY)
    engine.block.setPositionYMode(block, "Absolute")
    engine.block.setPositionY(block, posY)

    engine.block.setSelected(block, true)
    engine.editor.addUndoStep()
}

export const setSelectedBlockColor = (creativeEngine, color) => {
    let selectedBlock = creativeEngine.block.findAllSelected()[0]

    if(!selectedBlock){
        return
    }

    setProperty(creativeEngine, selectedBlock, "fill/solid/color", color)
}

export const getSelectedBlockColor = (creativeEngine) => {
    let selectedBlock = creativeEngine.block.findAllSelected()[0]

    if(!selectedBlock){
        return
    }

    return getProperty(creativeEngine, selectedBlock, "fill/solid/color")
}

export const setSelectedBlockTextAlignment = (creativeEngine, alignment) => {
    let selectedBlock = creativeEngine.block.findAllSelected()[0]

    if(!selectedBlock){
        return
    }

    setProperty(creativeEngine, selectedBlock, "text/horizontalAlignment", alignment)
}

export function setProperty(engine, blockId, propertyName, ...values) {
    const blockType = engine.block.getPropertyType(propertyName)
    const typeDependentMethodName = BLOCK_PROPERTY_METHODS(engine)[blockType]

    if (typeDependentMethodName.set) {
        if (Array.isArray(values)) {
            return engine.block[typeDependentMethodName.set](
                blockId,
                propertyName,
                ...values
            )
        } else {
            return engine.block[typeDependentMethodName.set](
                blockId,
                propertyName,
                values
            )
        }
    }
}

export function alignBlock(engine, blockId, verticalAlignment, horizontalAlignment){
    engine.block.alignHorizontally([blockId], horizontalAlignment)
    engine.block.alignVertically([blockId], verticalAlignment)

    let offsetX = 0
    let offsetY = 0

    if(verticalAlignment === "Top"){
        if(horizontalAlignment === "Left"){
            offsetX = 0.2
            offsetY = 0.2
        }
        else if(horizontalAlignment === "Center"){
            offsetY = 0.2
        }
        else if(horizontalAlignment === "Right"){
            offsetX = -0.2
            offsetY = 0.2
        }
    }
    else if(verticalAlignment === "Bottom"){
        if(horizontalAlignment === "Left"){
            offsetX = 0.2
            offsetY = -0.2
        }
        else if(horizontalAlignment === "Center"){
            offsetY = -0.2
        }
        else if(horizontalAlignment === "Right"){
            offsetX = -0.2
            offsetY = -0.2
        }
    }

    let oldX = engine.block.getPositionX(blockId)
    let oldY = engine.block.getPositionY(blockId)

    let newX = oldX + offsetX
    let newY = oldY + offsetY

    engine.block.setPositionX(blockId, newX)
    engine.block.setPositionY(blockId, newY)
}

export function getProperty(engine, blockId, propertyName) {
    const blockType = engine.block.getPropertyType(propertyName)
    const typeDependentMethodName = BLOCK_PROPERTY_METHODS(engine)[blockType]
    if (typeDependentMethodName.get) {
        return engine.block[typeDependentMethodName.get](blockId, propertyName)
    }
}


const BLOCK_PROPERTY_METHODS = (engine) => ({
    Float: {
        get: "getFloat",
        set: "setFloat",
    },
    Bool: {
        get: "getBool",
        set: "setBool",
    },
    String: {
        get: "getString",
        set: "setString",
    },
    Color: {
        get: "getColor",
        set: "setColor",
    },
    Enum: {
        get: "getEnum",
        set: "setEnum",
    },
})

export function createBleedLine(creativeEngine, parentId, color, widthOffset, heightOffset, posX, posY){
    if(!creativeEngine){
        return
    }

    const block = creativeEngine.block.create('graphic');
    const rect = creativeEngine.block.createShape('rect');

    creativeEngine.block.setShape(block, rect)

    creativeEngine.block.setStrokeEnabled(block, true);
    creativeEngine.block.setStrokeWidth(block, 0.01);
    creativeEngine.block.setStrokeColor(block, color);
    creativeEngine.block.setStrokeStyle(block, 'LongDashed');

    let pageWidth = creativeEngine.block.getWidth(parentId)
    let pageHeight = creativeEngine.block.getHeight(parentId)

    creativeEngine.block.setWidth(block, pageWidth + widthOffset)
    creativeEngine.block.setHeight(block, pageHeight + heightOffset)
    creativeEngine.block.setPositionX(block, posX)
    creativeEngine.block.setPositionY(block, posY)

    creativeEngine.block.setName(block, SceneBlockNames.SafeZone)
    creativeEngine.block.setIncludedInExport(block, false)

    creativeEngine.block.setScopeEnabled(block, "editor/select", false)

    creativeEngine.block.setVisible(block, true)
    creativeEngine.block.setAlwaysOnTop(block, true)

    creativeEngine.block.appendChild(parentId, block)
}

export function disableBlocksByName(creativeEngine, name){
    if(!creativeEngine){
        return
    }

    let bleeds = creativeEngine.block.findByName(name)

    for(let id of bleeds){
        creativeEngine.block.setIncludedInExport(id, false)
        creativeEngine.block.setScopeEnabled(id, "editor/select", false)
    }
}

export function printNamesOfAllBlocks(creativeEngine){
    if(!creativeEngine){
        return
    }

    let blocks = creativeEngine.block.findAll()

    for(let block of blocks){
        let blockName = creativeEngine.block.getName(block);
        if(blockName === SceneBlockNames.Postcard_Stamp){
            printPropertValuesForBlock(creativeEngine, block, blockName)
        }
        logger.debug(blockName, block)
    }
}

export function printPropertValuesForBlock(creativeEngine, block, blockName){
    if(!creativeEngine){
        return
    }

    let propertyNames = creativeEngine.block.findAllProperties(block)

    for(let propertyName of propertyNames){
        try{
            var propertyType = creativeEngine.block.getPropertyType(propertyName)
            var propertyValue = null
            if(propertyType === "String"){
                propertyValue = creativeEngine.block.getString(block, propertyName)
            } else if(propertyType === "Float"){
                propertyValue = creativeEngine.block.getFloat(block, propertyName)
            } else if(propertyType === "Bool"){
                propertyValue = creativeEngine.block.getBool(block, propertyName)
            } else if(propertyType === "Color"){
                propertyValue = creativeEngine.block.getColor(block, propertyName)
            }
            
            logger.debug(block, blockName, propertyType, propertyName, propertyValue)
        } catch(ex){
            logger.warn(ex, "Failed to get property value", propertyName)
        }
    }
}

export function createBleedText(creativeEngine, parentId, text, color, fontSize, xOffset, yOffset, blockName){
    if(!creativeEngine){
        return
    }

    const block = creativeEngine.block.create('text');

    let pageHeight = creativeEngine.block.getHeight(parentId)
    // const currentTypeface = creativeEngine.block.getTypeface(block);
    // creativeEngine.block.setFont(block, "extensions/ly.img.cesdk.fonts/fonts/Archivo/static/Archivo/Archivo-Regular.ttf", currentTypeface);
    creativeEngine.block.setFloat(block, "text/fontSize", fontSize)
    creativeEngine.block.setEnum(block, "text/horizontalAlignment", "Left")
    creativeEngine.block.setName(block, blockName)
    creativeEngine.block.setTextColor(block, color)
    creativeEngine.block.replaceText(block, text)

    creativeEngine.block.setPositionX(block, 0 + xOffset)
    creativeEngine.block.setPositionY(block, pageHeight + yOffset)

    creativeEngine.block.setScopeEnabled(block, "editor/select", false)
    creativeEngine.block.setIncludedInExport(block, false)

    creativeEngine.block.setVisible(block, true)

    creativeEngine.block.appendChild(parentId, block)
}

export function addBorderToPage(creativeEngine, pageId){
    if(!creativeEngine){
        return
    }

    const block = creativeEngine.block.create('graphic');
    const rect = creativeEngine.block.createShape('rect');

    creativeEngine.block.setShape(block, rect)

    creativeEngine.block.setStrokeEnabled(block, true);
    creativeEngine.block.setStrokeWidth(block, 0.01);
    creativeEngine.block.setStrokeColor(block, {r: 0.4, g: 0.4, b: 0.4, a: 1});
    creativeEngine.block.setStrokeStyle(block, 'Solid');

    let pageWidth = creativeEngine.block.getWidth(pageId)
    let pageHeight = creativeEngine.block.getHeight(pageId)

    creativeEngine.block.setWidth(block, pageWidth)
    creativeEngine.block.setHeight(block, pageHeight)
    creativeEngine.block.setPositionX(block, 0)
    creativeEngine.block.setPositionY(block, 0)

    creativeEngine.block.setName(block, SceneBlockNames.Border)
    creativeEngine.block.setIncludedInExport(block, false)

    creativeEngine.block.setScopeEnabled(block, "editor/select", false)

    creativeEngine.block.appendChild(pageId, block)
}

//Creates an invisible text field that contains the version
export function setVersion(creativeEngine, version){
    if(!creativeEngine){
        return
    }

    let block;
    let versionTexts = creativeEngine.block.findByName(SceneBlockNames.Version)

    if(versionTexts.length){
        block = versionTexts[0]
    }
    else{
        block = creativeEngine.block.create('text');
    }

    creativeEngine.block.setName(block, SceneBlockNames.Version)
    creativeEngine.block.replaceText(block, version)
    creativeEngine.block.setBool(block, "visible", false)
    creativeEngine.block.setScopeEnabled(block, "editor/select", false)

    if(!versionTexts.length){
        creativeEngine.block.appendChild(creativeEngine.scene.getPages()[0], block)
    }
}

export function getVersion(creativeEngine){
    if(!creativeEngine){
        return
    }

    let versionTexts = creativeEngine.block.findByName(SceneBlockNames.Version)

    if(versionTexts.length){
        let block = versionTexts[0]

        return creativeEngine.block.getString(block, "text/text")
    }

    return null
}

export function stretchImageToFullBleed(creativeEngine, blockId, product){
    if(!creativeEngine){
        return
    }

    const isBifold = product === ProductTypes.HandwrittenBiFoldCard
    const currentPage = creativeEngine.scene.getCurrentPage();

    let pageWidth = creativeEngine.block.getWidth(currentPage)
    let pageHeight = creativeEngine.block.getHeight(currentPage)

    creativeEngine.block.setWidth(blockId, pageWidth + 0.2)
    creativeEngine.block.setHeight(blockId, (isBifold ? pageHeight / 2 : pageHeight) + 0.2)
    creativeEngine.block.setPositionX(blockId, -0.1)
    creativeEngine.block.setPositionY(blockId, isBifold ? 5.2 : -0.1)

    creativeEngine.block.setScopeEnabled(blockId, 'layer/crop', true)
    creativeEngine.block.adjustCropToFillFrame(blockId, 1)
}

export const createQRCode = (creativeEngine, currentPageBlockId) => {
    removeBlockByName(creativeEngine, SceneBlockNames.QRCode)
    const block = creativeEngine.block.create("graphic")
    creativeEngine.block.setName(block, SceneBlockNames.QRCode)
    const rectShape = creativeEngine.block.createShape('rect');
    creativeEngine.block.setShape(block, rectShape);
    creativeEngine.block.setHeightMode(block, "Absolute")
    creativeEngine.block.setHeight(block, 0.8)
    creativeEngine.block.setWidthMode(block, "Absolute")
    creativeEngine.block.setWidth(block, 0.8)
    creativeEngine.block.setPositionXMode(block, "Absolute")
    creativeEngine.block.setPositionX(block, 6.5)
    creativeEngine.block.setPositionYMode(block, "Absolute")
    creativeEngine.block.setPositionY(block, 9.6)
    creativeEngine.block.appendChild(currentPageBlockId, block)
    const imageFill = creativeEngine.block.createFill('image');
    creativeEngine.block.setString(imageFill, "fill/image/imageFileURI", "")
    creativeEngine.block.setFill(block, imageFill);
};

export const createKeepOutText = (engine) => {
    const text = engine.block.create('text');
    const backPage = engine.scene.getPages()[1]
    engine.block.appendChild(backPage, text)
    engine.block.setName(text, SceneBlockNames.OutsideKeepOutAreaText)
    engine.block.setWidthMode(text, 'Auto');
    engine.block.setHeightMode(text, 'Auto');
    engine.block.setFloat(text, "text/fontSize", 48)
    engine.block.replaceText(text, 'KEEP OUT AREA');
    engine.block.setTextColor(text, { r: 1, g: 0, b: 0, a: 1 });
    engine.block.setPositionXMode(text, "Absolute")
    engine.block.setPositionX(text, 1.1)
    engine.block.setPositionYMode(text, "Absolute")
    engine.block.setPositionY(text, 2.3)
    engine.block.setScopeEnabled(text, "editor/select", false)
    engine.block.setIncludedInExport(text, false);
};
