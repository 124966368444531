import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';

import { WorkflowContextProvider } from 'newStandard/src/contexts/useWorkflowContext';
import { ITemplate } from 'newStandard/src/services/template/types';
import useTemplateService from 'newStandard/src/services/template';
import ProductStep from 'newStandard/src/components/ProductStep';
import TemplateEditor from 'newStandard/src/features/Editor';
import Stepper from 'newStandard/src/components/Stepper';
import MDCard from 'material-ui/components/MDCard';
import Loader from 'components/loader';

import ReviewStep from './ReviewStep';

const TEMPLATE_STEPS = ['Product', 'Design', 'Review'];

export default function TemplatePage() {
  const params = useParams();
  const navigate = useNavigate();
  const templateId = parseInt(params.id || '');
  const { getTemplate, postTemplate } = useTemplateService();
  const [template, setTemplate] = useState<ITemplate>({} as ITemplate);

  const { mutate: updateTemplate, isPending } = useMutation({ mutationFn: postTemplate });
  const { data: templates, isError } = useQuery({
    queryKey: ['template', templateId],
    queryFn: () => getTemplate(templateId),
  });

  useEffect(() => {
    if (isError) navigate('/my-templates');
  }, [isError, navigate]);

  useEffect(() => {
    if (templates?.length) setTemplate(templates[0]);
  }, [templates, setTemplate]);

  const handleSave = (updatedTemplate: ITemplate, callback?: () => void) => {
    updateTemplate(updatedTemplate, {
      onSuccess: (t) => {
        callback?.();
        setTemplate(t);
      },
    });
  };

  if (!template.id) return <TemplatePage.Loading />;
  return (
    <WorkflowContextProvider
      steps={TEMPLATE_STEPS}
      template={template}
      setTemplate={setTemplate}
      save={handleSave}
      isSaving={isPending}
    >
      <ProductStep />
      <TemplateEditor key={template.product} />
      <ReviewStep />
    </WorkflowContextProvider>
  );
}

TemplatePage.Loading = function TemplatePageLoading() {
  return (
    <Stepper steps={TEMPLATE_STEPS} maxStepAllowed={1} changeStep={() => {}}>
      <MDCard>
        <Stack alignItems={'center'} p={2}>
          <Loader />
        </Stack>
      </MDCard>
    </Stepper>
  );
};
