import {
    Dialog,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import MDBox from "material-ui/components/MDBox"
import MDTypography from "material-ui/components/MDTypography"
import {Template} from "models/template"
import MDButton from "material-ui/components/MDButton"
import {useNavigate} from "react-router-dom"
import { useEffect, useRef, useState} from "react"
import TwoButtonWithOptionalFormFieldModal from "components/modal-with-two-buttons-and-textfield"
import InfoModal from "components/info-modal";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import TemplateService from "services/template";
import MDCard from "material-ui/components/MDCard";
import EditPencilIcon from "assets/icons/edit-pencil";
import RenameIcon from "assets/icons/rename";
import DeleteIcon from "assets/icons/delete";
import ShowTemplateImagesModal from "./components/show-template-images-modal";
// @ts-ignore
import {CreateOrderFlowSteps} from "../../models/order-creation-flow/create-order-flow-steps";
import CreateOrderFlow from "../create-order-flow";
import DuplicateIcon from "assets/icons/duplicate"
import CopyTemplateModal from "./components/copy-template-modal"
import { useGlobal } from "context/global-context"
import MDIconButton from "material-ui/components/MDIconButton"
import TemplateScenePreview from "components/template-scene-preview"
import CropFreeIcon from '@mui/icons-material/CropFree';
export interface TemplateCardProps {
    template: Template
    hideButtons?: boolean
    onClick?: Function
    onDoubleClick?: Function
    pointerCursor?: boolean
    selected?: boolean
    showPreviewButton?: boolean
    disabled?: boolean
}

// Data
function TemplateCard({template, hideButtons, disabled, onClick, onDoubleClick, pointerCursor = false, selected = false, showPreviewButton}: TemplateCardProps) {
    const navigate = useNavigate()
    const {deleteTemplate, postTemplate} = TemplateService()
    const queryClient = useQueryClient()
    const {setShowLoader} = useGlobal()

    const boundingRef = useRef(null)

    const [showTemplateImagesModal, setShowTemplateImagesModal] = useState<boolean>(false)

    const [showEditNameModal, setShowEditNameModal] = useState<boolean>(false)
    const [newTemplateName, setNewTemplateName] = useState<string>(template.name)

    const [showCreateNewOrderModal, setShowCreateNewOrderModal] = useState<boolean>(false)

    const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState<boolean>(false)

    const [showCopyTemplateModal, setShowCopyTemplateModal] = useState<boolean>(false)
    const [openPreview, setOpenPreview] = useState<boolean>(false)

    const [showPage1, setShowPage1] = useState<boolean>(false)
    const [showPage2, setShowPage2] = useState<boolean>(false)

    const renameTemplateNameMutation = useMutation({
        mutationFn: () => {
            return postTemplate({
                ...template,
                name: newTemplateName
            })
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowEditNameModal(false)
            queryClient.invalidateQueries({queryKey: ["templates"]})
        }
    })

    const deleteTemplateMutation = useMutation({
        mutationFn: () => {
            return deleteTemplate(template.id)
        },
        onMutate: () => {
            setShowLoader(true)
        },
        onSuccess: (data) => {
            setShowLoader(false)
            setShowDeleteTemplateModal(false)
            queryClient.invalidateQueries({queryKey: ["templates"]})
        }
    })

    const [containerWidth, setContainerWidth] = useState(0)

    const [imageWidth, setImageWidth] = useState<number>(0)
    const [imageHeight, setImageHeight] = useState<number>(0)

    useEffect(() => {
        let imageWidth = containerWidth * 0.5

        if(imageWidth >= 220){
            imageWidth = 220
        }

        setImageWidth(imageWidth)
        setImageHeight(imageWidth * 0.7)
    }, [containerWidth]);

    useEffect(() => {
        _setContainerWidth(boundingRef.current.getBoundingClientRect().width)

        // Disable exhaustive deps for this one since this useEffect isn't called when the boundingRef.current changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boundingRef.current])

    function _setContainerWidth(newWidth: number){
        setContainerWidth(newWidth)
    }

    return (
        <MDCard
            boxShadow={false}
            border={true}
            color={"light"}
            ref={boundingRef}
            sx={{ cursor: pointerCursor && !disabled? "pointer" : "auto", position: "relative" }}
            className={`${selected? "card-selected" : ''} ${disabled? "card-disabled" : ""}`}
        >
            <Grid container p={2} gap={"20px"} justifyContent={"space-between"} flexDirection={"column"} onClick={onClick && !disabled? () => onClick() : null} onDoubleClick={onDoubleClick && !disabled? () => onDoubleClick() : null}>
                <Grid item onClick={!onClick && !disabled? () => setShowTemplateImagesModal(true) : null} position={"relative"} width={"100%"} height={1.60 * imageHeight}>
                    {template?.hasDiscount && <Typography variant="caption" position={"absolute"} fontWeight={"bold"}>REQUIRES<br />COUPON</Typography>}
                    <MDCard sx={{
                        overflow: "hidden",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        height: imageHeight,
                        width: imageWidth,
                        display: "flex",
                        justifyContent: "center"
                    }} borderRadiusSize={0} className={"pdf-full-height"} border={true} borderColor={"darkest"} borderSize={2} boxShadow={true} boxShadowSize={"large"}>
                        <img alt={""} style={{display: showPage1? "block" : "none", width: "100%", height: "100%"}} src={template.sceneFrontThumb} onLoad={() => {setShowPage1(true)}}/>
                    </MDCard>

                    <MDCard sx={{
                        overflow: "hidden",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: imageHeight,
                        width: imageWidth,
                        display: "flex",
                        justifyContent: "center"
                    }} borderRadiusSize={0} className={"pdf-full-height"} border={true} borderColor={"darkest"} borderSize={2} boxShadow={true} boxShadowSize={"large"}>
                        <img alt={""} style={{display: showPage2? "block" : "none",width: "100%", height: "100%"}} src={template.sceneBackThumb} onLoad={() => {setShowPage2(true)}}/>
                    </MDCard>
                </Grid>

                <Grid item width={"100%"}>
                    <MDTypography variant={"h4"} fontWeight="bold" color="text" sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap"
                    }}>
                        {template.name? template.name : <>&nbsp;</>}
                    </MDTypography>

                    <MDTypography variant="h5" fontWeight="regular" color="text" sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap"
                    }}>
                        {template.product}
                    </MDTypography>

                    {!hideButtons ?
                        <MDBox mt={2}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <MDButton
                                    color="secondary"
                                    variant={"transparent"}
                                    onClick={() => setShowCreateNewOrderModal(true)}
                                >
                                    Create Order
                                </MDButton>
                                <Stack direction="row" gap={"5px"}>
                                    <Tooltip title="Edit template">
                                        <IconButton sx={{ padding: 0 }} color={"secondary"} onClick={() => navigate(`/template/${template.id}`)}>
                                            <EditPencilIcon sx={{ width: "21px", height: "21px" }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit Name">
                                        <IconButton sx={{ padding: 0 }} color={"secondary"} onClick={() => setShowEditNameModal(true)}>
                                            <RenameIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Copy template">
                                        <IconButton sx={{ padding: 0 }} color={"secondary"}
                                            onClick={() =>
                                                setShowCopyTemplateModal(true)
                                            }
                                        >
                                            <DuplicateIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Template">
                                        <MDBox>
                                            <IconButton disabled={!template.canDelete}
                                                sx={{ padding: 0 }} color={"secondary"} onClick={() => setShowDeleteTemplateModal(true)}>
                                                <DeleteIcon sx={{ width: "21px", height: "21px", transform: "translate(0px, -3px)" }} />
                                            </IconButton>
                                        </MDBox>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </MDBox>
                        :
                        null
                    }
                </Grid>
            </Grid>
            
            {showPreviewButton && (
                <MDBox sx={{ zIndex: 900, position: "absolute", bottom: "18px", right: "8px" }}>
                    <MDIconButton
                        disabled={disabled}
                        borderRadiusSize="lg"
                        sx={{ padding: "5px", width: "50px", height: "30px", marginRight: "6px" }}
                        onClick={() => setOpenPreview(true)}>
                        <CropFreeIcon />
                    </MDIconButton>
                </MDBox>
            )}

            {/* TODO: Replace this with PreviewCardImagesModal when the pngs are ready */}
            <ShowTemplateImagesModal
                show={showTemplateImagesModal}
                setShow={setShowTemplateImagesModal}
                template={template}
            />

            <TwoButtonWithOptionalFormFieldModal
                open={showEditNameModal}
                onClose={() => setShowEditNameModal(false)}
                formFieldLabel="Template Name"
                formFieldHelperText="Rename your template"
                formFieldValue={newTemplateName}
                formFieldOnChange={setNewTemplateName}
                primaryButtonText="Save"
                primaryButtonOnClick={() => renameTemplateNameMutation.mutate()}
                secondaryButtonText="Cancel"
                secondaryButtonOnClick={() => setShowEditNameModal(false)}
            />

            <CopyTemplateModal 
                open={showCopyTemplateModal} 
                setOpen={setShowCopyTemplateModal} 
                template={template}
            />

            <InfoModal
                show={showDeleteTemplateModal}
                setShow={setShowDeleteTemplateModal}
                headerText={"Are you sure you want to delete this template?"}
                showCancelButton={true}
                showConfirmButton={true}
                cancelButtonOnClick={() => setShowDeleteTemplateModal(false)}
                confirmButtonOnClick={() => deleteTemplateMutation.mutate()}
            />

            <CreateOrderFlow
                show={showCreateNewOrderModal}
                setShow={setShowCreateNewOrderModal}
                startAtStep={CreateOrderFlowSteps.Step3}
                template={template}
            />

            <Dialog fullWidth open={openPreview} maxWidth={false} sx={{ maxWidth: 2000, marginX: "auto" }} onClose={() => setOpenPreview(false)}>
              <TemplateScenePreview {...template} sx={{ padding: 2 }} />
            </Dialog>
        </MDCard>
    )
}

export default TemplateCard
