import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT, { IObjectResult } from 'services/endpoint';

import { IOrderRecipient, IOrderRecipientData, IOrderRecipientFilterParams, IOrderRecipientIssues } from './types';

const API_PATH = `${API_ENDPOINT}/orderRecipient`;

export default function useOrderRecipientService() {
  const { execute } = useFetchWithMsal();

  const getOrderRecipients = (
    id: number,
    params: IOrderRecipientFilterParams
  ): Promise<IObjectResult<IOrderRecipientData>> => {
    let queryParams = `page=${params.page ?? 1}&pageSize=${params.pageSize ?? 20}`;
    if (params.showWarning) queryParams += `&showWarning=${params.showWarning}`;
    if (params.showError) queryParams += `&showError=${params.showError}`;
    if (params.sortBy) queryParams += `&sortBy=${encodeURIComponent(params.sortBy)}`;
    if (params.sortDirection) queryParams += `&sortDirection=${encodeURIComponent(params.sortDirection)}`;
    if (params.search) queryParams += `&search=${encodeURIComponent(params.search)}`;
    return execute({ endpoint: `${API_PATH}/getPaginated/${id}?${queryParams}`, method: 'GET' });
  };

  const getOrderRecipientsIssues = (id: number): Promise<IObjectResult<IOrderRecipientIssues>> => {
    return execute({ endpoint: `${API_PATH}/getIssues/${id}`, method: 'GET' });
  };

  const postOrderRecipient = (orderRecipient: IOrderRecipient) => {
    return execute({ endpoint: API_PATH, method: 'POST', data: orderRecipient });
  };

  const deleteOrderRecipientsFromAnOrder = (id: number, recipientIds: number[]) => {
    return execute({ endpoint: `${API_PATH}/deletefromorder/${id}`, method: 'DELETE', data: recipientIds });
  };

  const addFromAddressBook = (
    orderId: number,
    recipientIds: number[],
    lastSearchParams: object,
    selectedAll: boolean
  ) => {
    return execute({
      endpoint: `${API_PATH}/addfromaddressbook/${orderId}`,
      method: 'POST',
      data: { recipientIds, lastSearchParams, selectedAll },
    });
  };

  const addFromRecipientSearch = (orderId: number, recipientSearchId: (number | string)[]) => {
    return execute({ endpoint: `${API_PATH}/addfromradiussearch/${orderId}`, method: 'POST', data: recipientSearchId });
  };

  const postProcessOrderRecipients = (orderId: number) => {
    return execute({ endpoint: `${API_PATH}/postprocess/${orderId}`, method: 'POST' });
  };

  const getPostProcessStatus = (orderId: number) => {
    return execute({ endpoint: `${API_PATH}/GetPostProcessingStatus/${orderId}`, method: 'GET' });
  };

  return {
    getOrderRecipients,
    getOrderRecipientsIssues,
    postOrderRecipient,
    deleteOrderRecipientsFromAnOrder,
    addFromAddressBook,
    addFromRecipientSearch,
    postProcessOrderRecipients,
    getPostProcessStatus,
  };
}
