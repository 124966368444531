import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { Box, Checkbox, Grid, Icon, IconButton, InputAdornment, Tooltip, Typography } from '@mui/material';
import {
  GridColDef,
  GridPreferencePanelsValue,
  useGridApiRef,
  GridFilterModel,
  GridSortModel,
  DataGridProProps,
} from '@mui/x-data-grid-pro';
import MDInput from 'material-ui/components/MDInput';
import MDIconButton from 'material-ui/components/MDIconButton';
import MDCard from 'material-ui/components/MDCard';
import MDTypography from 'material-ui/components/MDTypography';
import FilterIcon from 'assets/icons/filter';
import ColumnIcon from 'assets/icons/column';
import MDButton from 'material-ui/components/MDButton';
import MDAlert from 'material-ui/components/MDAlert';
import StyledDataGrid from 'components/styled-data-grid';
import { GridSearchParams } from 'models/gridSearchParams';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

interface ITableRow {
  id: number | string;
  isUsed?: boolean;
}

interface TableProps<T extends ITableRow> extends DataGridProProps {
  rows: T[];
  columns: GridColDef[];
  isLoading?: boolean;
  serverDriven?: boolean;
  disableFilter?: boolean;
  allSelected: boolean;
  tableHeight?: number | string;
  selectedRows: number[];
  initialState?: GridInitialStatePro;
  totalRowCount: number;
  toolbarWithTitle?: boolean;
  pageSizeOptions?: number[];
  setAllSelected: (allSelected: boolean) => void;
  processRowUpdate?: (newRow: T) => void;
  setSelectedRows: (selectedRows: number[]) => void;
  setSearchParams: Dispatch<SetStateAction<GridSearchParams>>;
  renderToolbarFilterLeft?: () => JSX.Element;
  renderToolbarFilterRight?: () => JSX.Element;
  disabledRow?: (row: T) => boolean;
}

const Table = <T extends ITableRow>({
  rows,
  columns,
  isLoading,
  serverDriven,
  disableFilter,
  allSelected,
  tableHeight,
  selectedRows,
  initialState,
  totalRowCount,
  toolbarWithTitle,
  pageSizeOptions = [25, 50, 100],
  setAllSelected,
  setSelectedRows,
  setSearchParams,
  processRowUpdate,
  renderToolbarFilterLeft,
  renderToolbarFilterRight,
  disabledRow,
  ...rest
}: TableProps<T>) => {
  const gridApiRef = useGridApiRef();

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [allCurrentPageSelected, setAllCurrentPageSelected] = useState<boolean>(false);
  const selectedsLength = selectedRows?.length;
  const showSelectAllMessage = !isLoading && !!selectedsLength;
  const totalAvailableRowCount = disabledRow ? rows.filter((x) => !disabledRow(x)).length : totalRowCount;

  const messageContentOptions = {
    all: { title: `All ${totalAvailableRowCount} rows are selected.`, buttonText: 'Clear selection' },
    some: {
      title: `${selectedsLength} rows have been selected.`,
      buttonText: `Click here to select all ${totalAvailableRowCount} rows`,
    },
  };
  const messageContent = messageContentOptions[allSelected ? 'all' : 'some'];

  const firstColumn: GridColDef = {
    field: 'id',
    align: 'center',
    width: 55,
    filterable: false,
    resizable: false,
    disableReorder: true,
    hideable: false,
    hideSortIcons: true,
    sortable: false,
    renderHeader: () => <Checkbox checked={allCurrentPageSelected} onClick={handleSelectAllFromCurrentPage} />,
    renderCell: ({ id, row }) => {
      const isChecked = gridApiRef.current.isRowSelected(id);
      return (
        <Checkbox
          checked={isChecked}
          disabled={disabledRow?.(row)}
          onClick={() => handleRowSelect(Number(id.valueOf()), !isChecked)}
        />
      );
    },
  };

  const handleSelectAll = () => {
    if (allSelected) gridApiRef.current.setRowSelectionModel([]);
    else gridApiRef.current.selectRows(rows.filter((x) => !disabledRow?.(x)).map((row) => row.id));
    setAllCurrentPageSelected(!allSelected);
    setAllSelected(!allSelected);
  };

  const handleSelectAllFromCurrentPage = () => {
    if (allSelected && allCurrentPageSelected) setAllSelected(false);
    else if (!allCurrentPageSelected && rows.length === totalAvailableRowCount) setAllSelected(true);
    gridApiRef.current.selectRows(
      rows.filter((x) => !disabledRow?.(x)).map((x) => x.id),
      !allCurrentPageSelected
    );
    setAllCurrentPageSelected(!allCurrentPageSelected);
  };

  const handleRowSelect = (id: number, isChecked: boolean) => {
    if (allCurrentPageSelected && !isChecked) {
      setAllCurrentPageSelected(false);
      setAllSelected(false);
    }
    gridApiRef.current.selectRow(id, isChecked);
  };

  const handleApplyFilters = () => {
    gridApiRef.current.setRowSelectionModel([]);
    const filterItems = filterModel.items.map(
      (item) =>
        `field=${encodeURIComponent(item.field)}&operator=${encodeURIComponent(
          item.operator
        )}&value=${encodeURIComponent(item.value)}`
    );
    setSearchParams((prev) => ({ ...prev, filters: filterItems.join('&') }));
  };

  const handleRowUpdate = (newRow: T) => {
    gridApiRef.current.updateRows([newRow]);
    processRowUpdate(newRow);
  };

  useEffect(() => {
    if (allSelected) gridApiRef.current.selectRows(rows.map((row) => row.id));

    let check = true;
    if (rows && rows.length) {
      for (const row of rows) {
        if (!Array.from(gridApiRef.current.getSelectedRows().keys()).includes(row.id)) {
          check = false;
          break;
        }
      }
    }
    setAllCurrentPageSelected(check);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <MDCard color={'light'} boxShadow={false} borderRadiusSize={'xl'} sx={{ mb: 1, p: 1 }}>
        <Grid container justifyContent={'space-between'} alignItems={'center'} gap={1}>
          <Grid item xs>
            <Grid container gap={2} flexDirection={'row'}>
              {renderToolbarFilterLeft?.()}
              {serverDriven && !disableFilter && (
                <Grid item>
                  {toolbarWithTitle && (
                    <Typography variant="h4" textAlign={'center'} mb={1}>
                      Filter
                    </Typography>
                  )}
                  <Box display={'flex'} gap={1}>
                    <Tooltip title={'Filter'}>
                      <MDIconButton onClick={() => gridApiRef.current.showFilterPanel()}>
                        <FilterIcon />
                      </MDIconButton>
                    </Tooltip>
                    <Tooltip title={'Manage columns'}>
                      <MDIconButton
                        onClick={() => gridApiRef.current.showPreferences(GridPreferencePanelsValue.columns)}
                      >
                        <ColumnIcon />
                      </MDIconButton>
                    </Tooltip>
                    <MDButton onClick={handleApplyFilters}>Apply Filters</MDButton>
                  </Box>
                </Grid>
              )}
              {renderToolbarFilterRight?.()}
            </Grid>
          </Grid>

          <Grid item>
            <Formik
              initialValues={{ search: '' }}
              onSubmit={(values) => setSearchParams((prev) => ({ ...prev, search: values.search }))}
            >
              <Form>
                <Field name={'search'}>
                  {({ field }: FieldProps) => (
                    <MDInput
                      {...field}
                      placeholder="Search"
                      backgroundColor="dark"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type="submit">
                              <Icon>search</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </MDCard>

      {showSelectAllMessage && (
        <MDAlert color={'warning'} dismissible={false} backgroundColorOpacity={0.3} mb={1}>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item>
              <MDTypography color={'secondary'} textAlign={'center'} fontWeight={'normal'} sx={{ fontSize: 16 }}>
                {messageContent.title}
              </MDTypography>
            </Grid>

            <Grid item ml={1}>
              <MDTypography
                color={'primary'}
                textAlign={'center'}
                fontWeight={'normal'}
                sx={{ fontSize: 16, cursor: 'pointer' }}
                onClick={handleSelectAll}
              >
                {messageContent.buttonText}
              </MDTypography>
            </Grid>
          </Grid>
        </MDAlert>
      )}

      <Box height={tableHeight}>
        <StyledDataGrid
          {...rest}
          apiRef={gridApiRef}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
            ...initialState,
          }}
          pagination
          disableColumnMenu
          disableRowSelectionOnClick
          keepNonExistentRowsSelected={serverDriven}
          checkboxSelection={false}
          allSelected={allSelected}
          loading={isLoading}
          paginationMode={serverDriven ? 'server' : 'client'}
          filterMode={serverDriven ? 'server' : 'client'}
          rows={rows}
          rowCount={totalRowCount || 0}
          rowSelectionModel={selectedRows}
          columns={[firstColumn, ...columns]}
          sortModel={serverDriven ? sortModel : undefined}
          pageSizeOptions={pageSizeOptions}
          processRowUpdate={serverDriven ? (newRow) => handleRowUpdate(newRow) : undefined}
          onPaginationModelChange={
            serverDriven
              ? (model) => setSearchParams((prev) => ({ ...prev, page: model.page + 1, pageSize: model.pageSize }))
              : undefined
          }
          onRowSelectionModelChange={(selectionModel) => {
            const newRows = selectionModel as number[];
            if (newRows.length === totalRowCount) setAllSelected(true);
            setSelectedRows(newRows);
          }}
          onFilterModelChange={serverDriven ? (model: GridFilterModel) => setFilterModel(model) : undefined}
          onSortModelChange={
            serverDriven
              ? (models) => {
                  setSortModel(models);
                  if (models.length > 0) {
                    const { field, sort } = models[0];
                    setSearchParams((prev) => ({ ...prev, sortBy: field, sortDirection: sort }));
                  }
                }
              : undefined
          }
        />
      </Box>
    </>
  );
};

export default Table;
