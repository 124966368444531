import { useEffect } from 'react';
import CreativeEditorSDK from '@cesdk/cesdk-js';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';

import { useEditorContext } from '../contexts/useEditorContext';
import { envelopeConfig } from '../utils/defaultConfig';

export default function useCreateEnvelopeEditor(cesdkRef: React.MutableRefObject<HTMLDivElement | null>) {
  const { engine, envelopeEngine, setEnvelopeEngine } = useEditorContext();
  const { template } = useWorkflowContext();

  useEffect(() => {
    if (!cesdkRef?.current && !engine?.block) return;

    let cleanedUp = false;
    let instance: CreativeEditorSDK;

    CreativeEditorSDK.create(cesdkRef.current, envelopeConfig).then(async (cesdk) => {
      instance = cesdk;
      if (cleanedUp) return instance.dispose();
      await instance.addDefaultAssetSources();
      instance.ui.setDockOrder([]);
      instance.disableNoSceneWarning();
      instance.engine.editor.setSettingBool('page/title/show', false);
      setEnvelopeEngine(instance.engine);
    });

    return () => {
      cleanedUp = true;
      instance?.dispose();
      setEnvelopeEngine(undefined);
    };
  }, [cesdkRef, engine?.block, setEnvelopeEngine]);

  useEffect(() => {
    if (!envelopeEngine?.scene) return;
    const { protocol, host } = window.location;
    const random = Math.floor(Math.random() * 1000000000000000);
    const sceneUrl = `${protocol}//${host}/api/scene/${template.id}/Envelope/?${random}`;
    envelopeEngine.scene.loadFromURL(sceneUrl);
  }, [envelopeEngine?.scene, template.id]);
}
