import useFetchWithMsal from 'hooks/useFetch';
import API_ENDPOINT from 'services/endpoint';
import { ITemplate } from './types';

const useTemplateService = () => {
  const { execute } = useFetchWithMsal();

  const API_PATH = `${API_ENDPOINT}/orderTemplates`;

  const getTemplates = () => {
    return execute({ endpoint: API_PATH, method: 'GET' });
  };

  const getTemplate = (id: number) => {
    return execute({ endpoint: `${API_PATH}/${id}`, method: 'GET' });
  };

  const postTemplate = (template: ITemplate) => {
    return execute({ endpoint: API_PATH, method: 'POST', data: template });
  };

  const createOrderFromTemplate = (name: string, templateId: number) => {
    return execute({ endpoint: `${API_PATH}/createOrder/${templateId}`, method: 'POST', data: { name } });
  };

  const createTemplateFromOrder = (id: number, templateName: string, userIdToCopyTo?: number) => {
    return execute({
      endpoint: `${API_PATH}/createfromorder/${id}`,
      method: 'POST',
      data: { templateName, userIdToCopyTo },
    });
  };

  const deleteTemplate = (id: number) => {
    return execute({ endpoint: `${API_PATH}/delete/${id}`, method: 'POST' });
  };

  return { getTemplates, createOrderFromTemplate, deleteTemplate, postTemplate, createTemplateFromOrder, getTemplate };
};

export default useTemplateService;
