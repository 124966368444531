import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CircularProgress, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';

import { RecipientSearchStatus } from 'models/recipient-search/recipientSearchStatus';
import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import { RecipientSearch } from 'models/recipient-search/recipientSearch';
import RecipientSearchService from 'services/recipient-search';
import { formatDate, formatPrice } from 'helpers/formatters';
import StyledDataGrid from 'components/styled-data-grid';
import MDButton from 'material-ui/components/MDButton';

const statusOptions = {
  [RecipientSearchStatus.New]: 'Draft',
  [RecipientSearchStatus.ProcessingCount]: 'Calculating Recipients',
  [RecipientSearchStatus.ReadyForPayment]: 'Checkout',
  [RecipientSearchStatus.ProcessingOrder]: 'Processing Order',
  [RecipientSearchStatus.Complete]: 'Complete',
  [RecipientSearchStatus.Error]: 'Error',
  [RecipientSearchStatus.UnexpectedError]: 'Error',
  [RecipientSearchStatus.Paid]: 'Paid',
};

const columns: GridColDef[] = [
  {
    headerName: 'Last Modified Date',
    field: 'updatedDate',
    width: 200,
    valueFormatter: ({ value }) => formatDate(value),
  },
  { headerName: 'Search Name', field: 'name', width: 350, flex: 1 },
  {
    headerName: 'Status',
    field: 'status',
    width: 120,
    valueGetter: (params) => statusOptions[params.row.status as RecipientSearchStatus],
  },
  { headerName: 'Recipients', field: 'totalDesiredQuantity', width: 120 },
  {
    headerName: 'Total Cost',
    field: 'totalPrice',
    width: 120,
    valueFormatter: (params) => (params.value ? `${formatPrice(params.value)}` : null),
  },
];

interface AddRecipientsFromRecipientSearchModalProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function AddRecipientsFromRecipientSearchModal({
  show,
  onClose,
  onSubmit,
}: AddRecipientsFromRecipientSearchModalProps) {
  const params = useParams();
  const orderId = parseInt(params.id || '');

  const [search, setSearch] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  const { addFromRecipientSearch } = useOrderRecipientService();
  const { getRecipientSearches } = RecipientSearchService();

  const { data: { payload: recipientSearches = [] } = {}, isLoading } = useQuery({
    queryKey: ['recipientSearches'],
    queryFn: getRecipientSearches,
  });

  const { mutate: addRecipients, isPending } = useMutation({
    mutationFn: () => addFromRecipientSearch(orderId, selectedRows),
    onSuccess: () => {
      setSelectedRows([]);
      onSubmit();
      onClose();
    },
  });

  const handleCloseModal = () => {
    if (isPending) return;
    setSelectedRows([]);
    onClose();
  };

  const filterRows = (row: RecipientSearch) => {
    if (row.status !== RecipientSearchStatus.Complete) return false;
    if (!search) return true;
    if (row.name.toLowerCase().includes(search.toLowerCase())) return true;
    return false;
  };

  return (
    <Dialog open={show} onClose={handleCloseModal} fullWidth maxWidth="lg">
      <DialogContent>
        <DialogTitle display={'flex'} justifyContent={'space-between'}>
          Add recipients from your recipient searches
          <TextField value={search} placeholder="Search..." onChange={(evt) => setSearch(evt.target.value)} />
        </DialogTitle>
        <StyledDataGrid
          pagination
          checkboxSelection
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          rowSelectionModel={selectedRows}
          rows={recipientSearches.filter(filterRows)}
          initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
          onRowSelectionModelChange={(selectionModel) => setSelectedRows(selectionModel)}
          sx={{ maxHeight: '55vh' }}
        />
        <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
          <MDButton circular disabled={isPending} color="light" onClick={handleCloseModal}>
            Cancel
          </MDButton>
          <MDButton
            circular
            onClick={() => addRecipients()}
            disabled={!selectedRows.length || isPending}
            sx={{ width: 84 }}
          >
            {isPending ? <CircularProgress size={18} color="inherit" /> : 'Save'}
          </MDButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
