import { ignoreList } from './ignoreList';

type IFont = {
  [key in 'single' | 'double']: {
    [key: string]: { [key: string]: string[] };
  };
};

// * Replace glyphs with normal text

const getBaseCharacter = (glyph: string, fontObj: IFont) => {
  if (glyph === 'Q') return glyph; // Q is being interpreted as a space in fontObj json files
  for (let baseCharacter in fontObj.single) {
    for (let position in fontObj.single[baseCharacter]) {
      if (fontObj.single[baseCharacter][position].includes(glyph)) {
        return baseCharacter;
      }
    }
  }
  for (let baseCharacter in fontObj.double) {
    for (let position in fontObj.double[baseCharacter]) {
      if (fontObj.double[baseCharacter][position].includes(glyph)) {
        return baseCharacter;
      }
    }
  }
  return glyph; // return the glyph itself if no base character is found
};

export const removeGlyphs = (fromText: string, fontGlyphsJsonText?: string) => {
  if (!fontGlyphsJsonText) return fromText;
  const fontGlyphsJson = JSON.parse(fontGlyphsJsonText);
  let outputText = '';
  for (let i = 0; i < fromText.length; i++) {
    const glyph = fromText[i];
    const baseCharacter = getBaseCharacter(glyph, fontGlyphsJson);
    outputText += baseCharacter;
  }
  return outputText;
};

// * Replace normal text with glyphs

const getVariation = (baseCharacter: string, charType: string, variationsTable: IFont) => {
  if (baseCharacter === ' ') return baseCharacter; // Space variations mess with word selections
  let variation;
  try {
    const tableType = charType === 'double' ? 'double' : 'single';
    variation = variationsTable[tableType][baseCharacter][charType].shift();
    if (ignoreList.includes(variation.charCodeAt(0)) || variation.length > 1) {
      variation = baseCharacter;
    } else {
      variationsTable[tableType][baseCharacter][charType].push(variation);
    }
  } catch (error) {
    variation = baseCharacter;
  }
  return variation;
};

const scriptalizeText = (fontObj: IFont, text: string) => {
  let outputText = '';
  if (!text) return outputText;
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    let charType;
    if (char === ' ') {
      charType = 'start';
    } else if (i === 0 || text[i - 1] === ' ') {
      charType = 'start';
    } else if (i === text.length - 1 || text[i + 1] === ' ') {
      charType = 'end';
    } else {
      charType = 'in';
    }
    outputText += getVariation(char, charType, fontObj);
  }
  return outputText;
};

export const addGlyphs = (fromText: string, fontGlyphsJsonText: string, hideVariations?: boolean) => {
  if (hideVariations) return fromText;
  const fontGlyphsJson = JSON.parse(fontGlyphsJsonText);
  return scriptalizeText(fontGlyphsJson, fromText);
};
