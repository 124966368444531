import useFetchWithMsal from "hooks/useFetch";
import API_ENDPOINT, { IObjectResult } from "../endpoint";
import {IVM_CopyOrder} from "models/views/copyOrderVm";
import {Order} from "models/order";
import {OrderParameter} from "models/orderParameter";
import {IVM_GridOrder} from "models/views/gridOrderVm";
import {GridSearchParams} from "../../models/gridSearchParams";
import { ICheckout } from "models/checkout";

function OrderService() {
    const {execute} = useFetchWithMsal()

    const API_PATH = `${API_ENDPOINT}/orders`

    // Gets an order
    function getOrder(id: number) {
        return execute({
            endpoint: `${API_PATH}/${id}`,
            method: "GET"
        })
    }

    function getOrders() {
        return execute({
            endpoint: `${API_PATH}`,
            method: "GET"
        })
    }

    function copyOrder(data: IVM_CopyOrder) {
        return execute({
            endpoint: `${API_PATH}/copy`,
            method: "POST",
            data: data
        })
    }

    function getOrderRecipients(id: number) {
        return execute({
            endpoint: `${API_ENDPOINT}/orderRecipient/${id}`,
            method: "GET"
        })
    }

    // Creates an order from a recipient search and a selected template or pre-made design
    function createOrderFromRecipientSearchAndTemplateOrDesign(orderName: string, recipientSearchId: number, templateId?: number, designId?: number, productId?: number) {
        return execute({
            endpoint: `${API_PATH}/createfromrecipientsearch/`,
            method: "POST",
            data: {
                orderName: orderName,
                recipientSearchId: recipientSearchId,
                templateId: templateId,
                designId: designId,
                productId: productId
            }
        })
    }

    // Creates an order from a list of recipients and a selected template or pre-made design
    function createOrderFromRecipientsAndTemplateOrDesign(orderName: string, recipientIds: number[], lastSearchParams: GridSearchParams, templateId: number, designId: string, selectedAll: boolean) {
        return execute({
            endpoint: `${API_PATH}/createfromrecipients`,
            method: "POST",
            data: {
                orderName: orderName,
                recipientIds: recipientIds,
                templateId: templateId,
                designId: designId,
                lastSearchParams: lastSearchParams,
                selectedAll: selectedAll
            }
        })
    }

    function createOrderFromPremadeDesign(orderName: string, designId: number, productId: number){
        return execute({
            endpoint: `${API_PATH}/createfromdesigntemplate`,
            method: "POST",
            data: {
                orderName: orderName,
                designId: designId,
                productId: productId
            }
        })
    }

    // Deletes an order
    function deleteOrder(id: number) {
        return execute({
            endpoint: `${API_PATH}/delete/${id}`,
            method: "DELETE"
        })
    }

    // Changes the name of an order
    function changeOrderName(order: IVM_GridOrder) {
        return execute({
            endpoint: `${API_PATH}/changeName`,
            method: "POST",
            data: order
        })
    }

    function postOrder(order: Order){
        return execute({
            endpoint: `${API_PATH}`,
            method: "POST",
            data: order
        })
    }

    function getOrderCheckout(orderId: number): Promise<IObjectResult<ICheckout>>{
        return execute({
            endpoint: `${API_ENDPOINT}/checkout/${orderId}`,
            method: "GET"
        })
    }

    function postOrderCheckout(orderId: number){
        return execute({
            endpoint: `${API_ENDPOINT}/checkout/${orderId}`,
            method: "POST"
        })
    }

    function getOrderParameters(orderId: number): Promise<IObjectResult<OrderParameter[]>>{
        return execute({
            endpoint: `${API_PATH}/${orderId}/parameters`,
            method: "GET"
        })
    }

    function postOrderParameters(orderId: number, orderParameters: OrderParameter[]){
        return execute({
            endpoint: `${API_PATH}/${orderId}/parameters`,
            method: "POST",
            data: orderParameters
        })
    }

    function updateOrderDesignTemplate(orderId: number, premadeDesignId: number){
        return execute({
            endpoint: `${API_PATH}/updateorderdesigntemplate`,
            method: "POST",
            data: {
                orderId: orderId,
                premadeDesignId: premadeDesignId
            }
        })
    }

    return {
        getOrder,
        getOrders,
        createOrderFromRecipientsAndTemplateOrDesign,
        createOrderFromRecipientSearchAndTemplateOrDesign,
        getOrderRecipients,
        deleteOrder,
        changeOrderName,
        copyOrder,
        postOrder,
        getOrderCheckout,
        postOrderCheckout,
        createOrderFromPremadeDesign,
        getOrderParameters,
        postOrderParameters,
        updateOrderDesignTemplate
    }
}

export default OrderService