import { IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { CheckCircleOutline, Edit, Report, Warning } from '@mui/icons-material';

import {
  IOrderRecipient,
  OrderRecipientError,
  OrderRecipientWarning,
} from 'newStandard/src/services/orderRecipient/types';

const warningOptions = {
  [OrderRecipientWarning.PotentiallyUndeliverable]: 'Potentially undeliverable',
  [OrderRecipientWarning.Undeliverable]: 'Undeliverable',
  [OrderRecipientWarning.MissingMailMergeField]: 'Missing mail merge fields',
};

const errorOptions = {
  [OrderRecipientError.MissingAddressFields]: 'Missing required address field(s) (Address 1, City, State, Zip)',
  [OrderRecipientError.MissingNameFields]:
    'Missing at least one required name field (First Name, Last Name, Organization)',
  [OrderRecipientError.NonUsAddress]: 'Non US Address',
};

const renderStatusTooltip = (row: IOrderRecipient) => {
  if (row.errors?.length) return errorOptions[row.errors[0]];
  if (row.warnings?.length) return warningOptions[row.warnings[0]];
  return 'Good to go!';
};

const renderStatusIcon = (row: IOrderRecipient) => {
  if (row.errors?.length) return <Report color="error" />;
  if (row.warnings?.length) return <Warning color="warning" />;
  return <CheckCircleOutline color="success" />;
};

const formatAddress = (params: GridValueGetterParams) => {
  return `${params.value || '-'}${params.row.address2 ? `, ${params.row.address2}` : ''}`;
};

const columnDef = (minWidth: number): Partial<GridColDef> => ({
  valueFormatter: ({ value }) => value ?? '-',
  headerAlign: 'center',
  align: 'center',
  minWidth,
});

export const columns = (editCallback: (recipient: IOrderRecipient) => void): GridColDef[] => [
  {
    type: 'actions',
    field: 'actions',
    width: 86,
    getActions: (params) => [
      <Tooltip title={'Edit'}>
        <IconButton onClick={() => editCallback(params.row)}>
          <Edit />
        </IconButton>
      </Tooltip>,
      <Tooltip title={renderStatusTooltip(params.row)}>{renderStatusIcon(params.row)}</Tooltip>,
    ],
  },
  { headerName: 'First Name', field: 'firstName', ...columnDef(120) },
  { headerName: 'Last Name', field: 'lastName', ...columnDef(150) },
  { headerName: 'Salutation', field: 'salutation', ...columnDef(120) },
  { headerName: 'To Organization', field: 'toOrganization', ...columnDef(150) },
  { headerName: 'Address', field: 'address1', ...columnDef(260), flex: 1, valueGetter: formatAddress },
  { headerName: 'City', field: 'city', ...columnDef(120) },
  { headerName: 'State', field: 'state', ...columnDef(60) },
  { headerName: 'Zip Code', field: 'zipCode', ...columnDef(86) },
  { headerName: 'Custom 1', field: 'custom1', ...columnDef(120) },
  { headerName: 'Custom 2', field: 'custom2', ...columnDef(120) },
  { headerName: 'Custom 3', field: 'custom3', ...columnDef(120) },
  { headerName: 'Custom 4', field: 'custom4', ...columnDef(120) },
  { headerName: 'Custom 5', field: 'custom5', ...columnDef(120) },
  { headerName: 'Custom 6', field: 'custom6', ...columnDef(120) },
  { headerName: 'Radius Pin Address', field: 'radiusPinAddress', ...columnDef(150) },
  { headerName: 'Radius Pin Street', field: 'radiusPinStreet', ...columnDef(150) },
];
