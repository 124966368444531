import { Close } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';

interface PanelWrapperProps {
  title: string;
  children: React.ReactNode;
  onPanelClose: () => void;
}

export default function PanelWrapper({ children, title, onPanelClose }: PanelWrapperProps) {
  return (
    <Stack
      top={70}
      left={108}
      zIndex={13}
      bottom={16}
      width={'100%'}
      maxWidth={432}
      position={'absolute'}
      borderLeft={'1px solid lightgray'}
      sx={{ backgroundColor: '#FFF' }}
    >
      <Box display="flex" justifyContent="flex-end" padding={'0px 20px'}>
        <IconButton
          onClick={() => onPanelClose()}
          sx={{
            color: 'black',
            borderRadius: '0.75rem',
            boxShadow: '0px 1px 2px 0px hsla(207, 18%, 10%, 0.16), 0px 0px 0px 0.5px hsla(207, 18%, 10%, 0.08)',
          }}
        >
          <Close sx={{ fill: 'black' }} />
        </IconButton>
      </Box>
      {children}
    </Stack>
  );
}
