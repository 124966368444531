import { CircularProgress } from '@mui/material';
import { PaymentMethod } from '@stripe/stripe-js';


import MDButton from 'material-ui/components/MDButton';

interface CheckoutModalProps {
  balance?: number;
  isPending: boolean;
  selectedCard: PaymentMethod;
  hasIncompleteCard: boolean;
  onCheckout: () => void;
}

export default function CheckoutButton({
  balance,
  isPending,
  selectedCard,
  hasIncompleteCard,
  onCheckout,
}: CheckoutModalProps) {
  const isSubmitDisabled = (isPending || !!(!selectedCard && hasIncompleteCard)) && !!balance;

  return (
    <MDButton fullWidth disabled={isSubmitDisabled} onClick={onCheckout}>
      {isPending ? <CircularProgress size={18} color="inherit" /> : 'Place your order'}
    </MDButton>
  );
}
