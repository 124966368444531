import { useEffect } from 'react';

import { useEditorContext } from '../contexts/useEditorContext';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { checkHasEnvelope } from '../utils/templateHelper';

export default function useStyledEditor() {
  const { template } = useWorkflowContext();
  const { engine, envelopeEngine, isSceneLoading } = useEditorContext();

  useEffect(() => {
    const envelopLoaded = checkHasEnvelope(template.product) ? envelopeEngine : true;
    if (!engine || !envelopLoaded || isSceneLoading) return;
    const shadowHost = document.querySelector('#root-shadow');
    if (!shadowHost) return;
    const shadowRoot = shadowHost.shadowRoot;
    const editorBorderElement = shadowRoot.querySelector('div[aria-label="CE.SDK"] > :first-child');
    // @ts-ignore
    if (editorBorderElement) editorBorderElement.style.borderRadius = '24px 0 0 0 ';
    const dockElement = shadowRoot.querySelector('div[data-cy="dock"]');
    // @ts-ignore
    if (dockElement) dockElement.style.padding = '16px';
    // @ts-ignore
    shadowHost.style.setProperty('--ubq-border_radius-s', '0.75rem');
  }, [engine, envelopeEngine, isSceneLoading, template.product]);
}
