import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, ListItem, ListItemText, Tooltip } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { RouteType } from 'newStandard/src/routes/types';
import { useGlobal } from 'context/global-context';

import { StyledListItemButton, StyledListItemIcon, StyledSidebarDivider } from './StyledSidebar';

interface SidebarItemProps {
  persistentTooltip?: boolean;
  tooltipText?: string;
  onClick?: () => void;
}

export default function SidebarItem({
  persistentTooltip,
  tooltipText,
  hasDivider,
  collapse,
  route,
  name,
  icon,
  hide,
  href,
  onClick,
}: SidebarItemProps & RouteType) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isSidebarOpen } = useGlobal();
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const isSelected = route === pathname;

  const handleClick = () => {
    if (collapse) setOpenCollapse(!openCollapse);
    else if (route) navigate(route);
    else if (href) window.location.href = href;
    else if (onClick) onClick();
  };

  if (hide) return null;

  return (
    <>
      <ListItem key={name} disablePadding sx={{ display: 'block' }}>
        <Tooltip
          title={tooltipText || name}
          placement="right"
          open={openTooltip}
          onClose={() => (!isSidebarOpen || persistentTooltip) && setOpenTooltip(false)}
          onOpen={() => (!isSidebarOpen || persistentTooltip) && setOpenTooltip(true)}
        >
          <StyledListItemButton isSidebarOpen={isSidebarOpen} selected={isSelected} onClick={handleClick}>
            <StyledListItemIcon isSidebarOpen={isSidebarOpen}>{icon}</StyledListItemIcon>
            <ListItemText
              primary={name}
              primaryTypographyProps={{ color: 'inherit', fontSize: 16 }}
              sx={{ opacity: isSidebarOpen ? 1 : 0 }}
            />
            {collapse && isSidebarOpen && <KeyboardArrowDown color="inherit" />}
          </StyledListItemButton>
        </Tooltip>
      </ListItem>
      {collapse && (
        <Collapse in={openCollapse} unmountOnExit sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          {Array.isArray(collapse)
            ? collapse.map((childRoute) => <SidebarItem key={childRoute.name} {...childRoute} />)
            : collapse}
        </Collapse>
      )}
      {hasDivider && <StyledSidebarDivider />}
    </>
  );
}
