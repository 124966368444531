import moment from 'moment';
import {
  Alert,
  Box,
  Chip,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { orderProducUsesLetterUSPSPricing } from 'models/enums/ProductTypes';
import RadioSelectWrapper from 'components/radio-select-wrapper';
import { IOrder } from 'newStandard/src/services/order/types';
import { PostageTypes } from 'models/enums/PostageTypes';
import { ICheckout } from 'models/checkout';

interface SelectDeliveryOptionProps {
  checkout?: ICheckout;
  isLoading: boolean;
  onChange: (order: IOrder) => void;
}

export default function SelectDeliveryOption({ checkout, isLoading, onChange }: SelectDeliveryOptionProps) {
  const { template } = useWorkflowContext<IOrder>();

  return (
    <Paper variant="outlined" sx={{ borderRadius: 4, py: 1, px: 2, mt: 2 }}>
      <Typography variant="h4" mb={1}>
        Delivery Option
      </Typography>
      <RadioGroup
        value={template.postageType}
        onChange={(evt) => onChange({ ...template, postageType: evt.target.value as PostageTypes })}
      >
        <Stack spacing={1}>
          <RadioSelectWrapper selected={template.postageType === PostageTypes.FirstClass} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={PostageTypes.FirstClass}
              label={
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h5">First Class Postage</Typography>
                  <Typography variant="h5" fontWeight={'normal'}>
                    (3-5 day USPS estimated delivery)
                  </Typography>
                  <Chip
                    label={
                      checkout?.postageTypePrices?.FirstClass
                        ? `+$${checkout.postageTypePrices.FirstClass.toFixed(2)}`
                        : orderProducUsesLetterUSPSPricing(template.product)
                        ? '+$0.15'
                        : '+$0.05'
                    }
                  />
                </Box>
              }
            />
          </RadioSelectWrapper>
          <RadioSelectWrapper selected={template.postageType === PostageTypes.Standard} padding={'2px 10px'}>
            <FormControlLabel
              control={<Radio />}
              value={PostageTypes.Standard}
              label={
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography variant="h5">Standard Postage</Typography>
                  <Typography variant="h5" fontWeight={'normal'}>
                    (10-14 day USPS estimated delivery)
                  </Typography>
                </Box>
              }
            />
          </RadioSelectWrapper>
        </Stack>
      </RadioGroup>

      <Alert severity="info" icon={false} sx={{ mt: 1, py: 0 }}>
        <Typography variant={'h5'} display={'flex'} alignItems={'center'}>
          USPS Est. Delivery:
          <Typography component={'span'} variant={'h5'} fontWeight={'normal'} ml={1} minWidth={200}>
            {isLoading ? (
              <Skeleton />
            ) : (
              `${moment(checkout?.estimatedDeliveryDateMin).format('ddd, MMM. Do')} - ${moment(
                checkout?.estimatedDeliveryDateMax
              ).format('ddd, MMM. Do')}`
            )}
          </Typography>
        </Typography>

        <Typography fontSize={12}>* Estimates not guaranteed by LettrLabs</Typography>
      </Alert>
    </Paper>
  );
}
