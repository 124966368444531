import { useRef } from 'react';
import { Box, Typography } from '@mui/material';

import useCreateEnvelopeEditor from '../hooks/useCreateEnvelopeEditor';
import useStyledEditor from '../hooks/useStyledEditor';
import ReturnAddressPanel from './ReturnAddressPanel';

export default function EnvelopeEditor() {
  const cesdkRef = useRef<HTMLDivElement>(null);

  useCreateEnvelopeEditor(cesdkRef);
  useStyledEditor();

  return (
    <>
      <Box zIndex={20}>
        <Box borderBottom={'1px solid lightgray'} padding={'15px 20px'}>
          <Typography fontSize={20} fontWeight={600}>
            Return Address
          </Typography>
        </Box>
        <ReturnAddressPanel />
      </Box>
      <Box ref={cesdkRef} style={{ height: '85vh', flex: 1, marginLeft: -108 }} />
    </>
  );
}
