import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Delete, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
} from '@mui/material';

import Table from 'components/table';
import { formatNumber } from 'helpers/formatters';
import MDCard from 'material-ui/components/MDCard';
import MDButton from 'material-ui/components/MDButton';
import OrderRecipientService from 'services/order-recipient';
import useDiscountCodesService from 'services/discount-codes';
import { defaultSearchParams } from 'components/table/table.utils';
import useOrderRecipientService from 'newStandard/src/services/orderRecipient';
import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { GridSearchParams, OrderRecipientsSearchParams } from 'models/gridSearchParams';
import SelectDiscountCodes, { ISelectedDiscountCode } from 'components/select-discount-codes';
import {
  IOrderRecipient,
  IOrderRecipientErrors,
  IOrderRecipientIssues,
  IOrderRecipientWarnings,
} from 'newStandard/src/services/orderRecipient/types';

import UpsertRecipientModal from './UpsertRecipientModal';
import AddRecipientsButton from './AddRecipientsButton';
import { columns } from './RecipientsStep.utils';
import HelpGuide from './HelpGuide';

export default function RecipientsStep() {
  const params = useParams();
  const queryClient = useQueryClient();
  const orderId = parseInt(params.id || '');
  const { assignCouponToOrder } = useDiscountCodesService();
  const { template, setTemplate, setMaxStepAllowed, setSaveSteps, changeStep } = useWorkflowContext();
  const { getOrderRecipients, getOrderRecipientsIssues, postProcessOrderRecipients } = useOrderRecipientService();
  // TODO: Update line bellow to be on the service above
  const { deletePaginatedOrderRecipients, getPostProcessStatus } = OrderRecipientService();

  const [couponError, setCouponError] = useState<string>('');
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [editRecipient, setEditRecipient] = useState<IOrderRecipient>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [hasDiscountError, setHasDiscountError] = useState<boolean>(false);
  const [selectedDiscount, setSelectedDiscount] = useState<ISelectedDiscountCode>(template);
  const [searchParams, setSearchParams] = useState<OrderRecipientsSearchParams>({
    ...defaultSearchParams,
    pageSize: 10,
    showWarning: false,
    showError: false,
  });

  const { mutate: assignCoupon } = useMutation({
    mutationFn: assignCouponToOrder,
    onSuccess: (response) => (response?.hasErrors ? setCouponError(response.errors?.[0] ?? '') : changeStep(4)),
  });

  const { mutate: deleteRecipients, isPending: isDeleting } = useMutation({
    mutationFn: () => deletePaginatedOrderRecipients(orderId, selectedRows, searchParams, selectedAll),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getOrderRecipientsIssues', orderId] });
      queryClient.invalidateQueries({ queryKey: ['orderRecipients', orderId] });
      setShowDeleteModal(false);
      setSelectedRows([]);
      if (selectedAll) {
        setSelectedAll(false);
        setSearchParams((prev) => ({ ...prev, search: '', showWarning: false, showError: false }));
      }
    },
  });

  const { mutate: processRecipients } = useMutation({
    mutationFn: () => postProcessOrderRecipients(orderId),
    onMutate: () => setIsProcessing(true),
    onSuccess: () => getProcess(),
  });

  const { data: process, refetch: getProcess } = useQuery({
    queryKey: ['postProcessStatus', orderId],
    queryFn: () => getPostProcessStatus(orderId),
    enabled: false,
  });

  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ['orderRecipients', orderId, searchParams],
    queryFn: () => getOrderRecipients(orderId, searchParams),
  });
  const { recipients = [], usedColumns = [], totalCount } = data?.payload || {};

  // TODO: add loading
  const { data: { payload: issues } = {} } = useQuery({
    queryKey: ['getOrderRecipientsIssues', orderId],
    queryFn: () => getOrderRecipientsIssues(orderId),
  });
  const {
    recipientWarningDetails = {} as IOrderRecipientWarnings,
    recipientErrorDetails = {} as IOrderRecipientErrors,
  } = issues || ({} as IOrderRecipientIssues);
  const { missingMailMergeField = 0, potentiallyUndeliverable = 0, undeliverable = 0 } = recipientWarningDetails;
  const { missingAddressFields = 0, missingNameFields = 0, nonUsAddress = 0 } = recipientErrorDetails;
  const errorsCount = missingAddressFields + nonUsAddress + missingNameFields;
  const warningsCount = potentiallyUndeliverable + undeliverable + missingMailMergeField;

  useEffect(() => {
    if (!recipients.length) return;
    setMaxStepAllowed((prev) => (recipients.length && !errorsCount ? 4 : prev < 4 ? prev : 3));
  }, [recipients.length, errorsCount, setMaxStepAllowed]);

  useEffect(() => {
    setSaveSteps((prev) => ({ ...prev, 3: handleNextClick }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSaveSteps]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (process?.hasErrors || !process?.payload) return;
      const processed = process.payload.processedRecipients;
      const total = process.payload.totalRecipients;

      if (processed >= total) {
        clearInterval(interval);
        setIsProcessing(false);
        queryClient.invalidateQueries({ queryKey: ['getOrderRecipientsIssues', orderId] });
        queryClient.invalidateQueries({ queryKey: ['orderRecipients', orderId] });
      } else getProcess();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [orderId, process, queryClient, getProcess]);

  const selectedCount = selectedRows?.length ?? 0;
  const tooltipDeleteTitle = selectedCount
    ? `Remove ${selectedAll ? 'All' : selectedCount} Recipient${selectedCount ? (selectedCount > 1 ? 's' : '') : 's'}`
    : 'Select an address to remove from the list';

  const handleNextClick = () => {
    if (!template.hasDiscount) return changeStep(4);
    const { couponListId, multiUseCouponId } = selectedDiscount;
    if (!couponListId && !multiUseCouponId) return setHasDiscountError(true);
    setTemplate((prev) => ({ ...prev, multiUseCouponId, couponListId }));
    assignCoupon({ ...selectedDiscount, orderId });
  };

  const handleAlertAction = (type: 'Warning' | 'Error') => {
    setSearchParams((prev) => ({ ...prev, [`show${type}`]: !prev[`show${type}`] }));
  };

  const renderAlertAction = (type: 'Warning' | 'Error') => {
    const show = searchParams[`show${type}`];
    const Icon = show ? VisibilityOffOutlined : VisibilityOutlined;
    return (
      <MDButton
        size="small"
        color="secondary"
        variant={show ? 'primary' : 'transparent'}
        onClick={() => handleAlertAction(type)}
      >
        <Icon sx={{ mr: 1, height: 18 }} /> {show ? 'Hide' : 'Show'} {type}
      </MDButton>
    );
  };

  return (
    <MDCard sx={{ p: 2 }}>
      <Grid container spacing={1} mb={1}>
        {!!warningsCount && (
          <Grid item xs={12} xl={6}>
            <Alert
              severity="warning"
              action={renderAlertAction('Warning')}
              sx={{ py: 0, alignItems: 'center', borderRadius: '12px' }}
            >
              <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'}>
                Warnings:
                {!!missingMailMergeField && <Chip label={`Missing Mail Merge Text: ${missingMailMergeField}`} />}
                {!!potentiallyUndeliverable && (
                  <Chip label={`Potentially Undeliverable: ${potentiallyUndeliverable}`} />
                )}
                {!!undeliverable && <Chip label={`Undeliverable: ${undeliverable}`} />}
              </Box>
            </Alert>
          </Grid>
        )}
        {!!errorsCount && (
          <Grid item xs={12} xl={6}>
            <Alert
              severity="error"
              action={renderAlertAction('Error')}
              sx={{ py: 0, alignItems: 'center', borderRadius: '12px' }}
            >
              <Box display={'flex'} gap={1} alignItems={'center'} fontWeight={'bold'}>
                Errors:
                {!!missingAddressFields && <Chip label={`Missing Address: ${missingAddressFields}`} />}
                {!!missingNameFields && <Chip label={`Missing Name Fields: ${missingNameFields}`} />}
                {!!nonUsAddress && <Chip label={`Non US Address: ${nonUsAddress}`} />}
              </Box>
            </Alert>
          </Grid>
        )}
      </Grid>
      <Table
        serverDriven
        disableFilter
        rows={recipients}
        columns={columns(setEditRecipient).filter(({ field }) =>
          ['actions', ...usedColumns].includes(field.toLowerCase())
        )}
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        autosizeOptions={{ columns: ['firstName'], includeOutliers: true, outliersFactor: 1.5, expand: true }}
        isLoading={isLoading || isRefetching}
        totalRowCount={totalCount ?? 0}
        selectedRows={selectedRows}
        allSelected={selectedAll}
        setAllSelected={setSelectedAll}
        setSearchParams={setSearchParams as React.Dispatch<React.SetStateAction<GridSearchParams>>}
        setSelectedRows={setSelectedRows}
        renderToolbarFilterLeft={() => (
          <Grid item display={'flex'} alignItems={'center'} gap={0.5} flex={1}>
            <AddRecipientsButton onRecipientsSubmit={processRecipients} />
            <HelpGuide />
            <Box mr="auto" />
            {template.hasDiscount && (
              <SelectDiscountCodes
                error={hasDiscountError}
                setError={setHasDiscountError}
                selectedDiscount={selectedDiscount}
                setSelectedDiscount={setSelectedDiscount}
                recipientsAmount={totalCount}
              />
            )}
            <Tooltip title={tooltipDeleteTitle}>
              <Box ml="auto">
                <IconButton color="error" disabled={!selectedCount} onClick={() => setShowDeleteModal(true)}>
                  <Delete />
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        )}
      />
      <Box display={'flex'} justifyContent={'space-between'} mt={2}>
        <MDButton color="light" onClick={() => changeStep(2)}>
          Back
        </MDButton>
        <MDButton disabled={!recipients.length || isRefetching || !!errorsCount} onClick={handleNextClick}>
          {isDeleting ? <CircularProgress size={18} color="inherit" /> : 'Next'}
        </MDButton>
      </Box>

      <UpsertRecipientModal
        key={editRecipient?.id}
        show={!!editRecipient}
        recipient={editRecipient}
        onClose={() => setEditRecipient(undefined)}
        onSubmit={() => processRecipients()}
      />

      <Dialog open={showDeleteModal} onClose={() => !isDeleting && setShowDeleteModal(false)} maxWidth="xs">
        <DialogContent>
          <DialogTitle p={0} whiteSpace={'pre-wrap'}>
            Are you sure you want to remove {selectedRows.length ? formatNumber(selectedRows.length) : 'all'} recipients
            from your list?
          </DialogTitle>
          <Box display="flex" gap={1} mt={2}>
            <MDButton circular fullWidth color="light" disabled={isDeleting} onClick={() => setShowDeleteModal(false)}>
              Cancel
            </MDButton>
            <MDButton circular fullWidth disabled={isDeleting} onClick={() => deleteRecipients()}>
              {isDeleting ? <CircularProgress size={18} color="inherit" /> : "Yes, I'm sure"}
            </MDButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isProcessing} maxWidth={false}>
        <DialogContent>
          <DialogTitle>Your recipients are being processed and it might take a while. Please wait</DialogTitle>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={!!couponError}
        autoHideDuration={6000}
        onClose={() => setCouponError('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setCouponError('')} severity="error" variant="standard" sx={{ py: 0 }}>
          {couponError}
        </Alert>
      </Snackbar>
    </MDCard>
  );
}
