import { MouseEventHandler, useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';

//hooks
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

//contexts
import { useGlobal } from 'context/global-context';

//models
import { IProfileRecipient } from 'models/profileRecipient';
import { GridSearchParams } from 'models/gridSearchParams';

//ui
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import MDIconButton from 'material-ui/components/MDIconButton';
import RecipientTag from 'components/recipient-tag';
import MDButton from 'material-ui/components/MDButton';
import Table from 'components/table';
import MDCard from 'material-ui/components/MDCard';

//services
import ProfileRecipientService from 'services/profile-recipient';
import ProfileService from 'services/profile';
import { formatNumber } from '../../helpers/formatters';

//modals
import ManageTagsModal from './components/manage-tags-modal';
import GroupRecipientsByTagsModal from './components/group-recipients-by-tags-modal';
import AddNewRecipientModal from './components/add-new-recipient-modal';
import BulkUpdateRecipientsModal from './components/bulk-update-recipients-modal';
import InfoModal from 'components/info-modal';
import CreateOrderFlow from 'components/create-order-flow';

//icons
import PlusIcon from 'assets/icons/plus';
import DownloadIcon from 'assets/icons/download';
import DeleteIcon from 'assets/icons/delete';
import EditPencilIcon from 'assets/icons/edit-pencil';
import ImportIcon from 'assets/icons/import';
import AddTagToRecipientIcon from 'assets/icons/add-tag-to-recipient';
import { AutoAwesomeOutlined, AutoFixHigh, AutoFixOff } from '@mui/icons-material';
import EnrichModal from './components/enrich-modal';
import { EnrichmentType } from 'models/enums/ProfileEnrichment';

const defaultSearchParams: GridSearchParams = {
  page: 1,
  pageSize: 100,
  sortBy: '',
  sortDirection: 'asc',
  search: '',
  filters: '',
};
const CSVBOX_LICENCE = process.env.REACT_APP_CSVBOX_PROFILE_RECIPIENTS;

const AddressBookPage = () => {
  const queryClient = useQueryClient();
  const { setShowLoader } = useGlobal();

  const { getProfileRecipientsServerPagination, deleteProfileRecipients, postProfileRecipient, generateRecipientsCsv } = ProfileRecipientService();
  const { getProfile } = ProfileService();

  const [selectedRecipients, setSelectedRecipients] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useState<GridSearchParams>(defaultSearchParams);
  const [showEditTagsModal, setShowEditTagsModal] = useState<boolean>(false);
  const [showBulkUpdateRecipientsModal, setShowBulkUpdateRecipientsModal] = useState<boolean>(false);
  const [showNewRecipientModal, setShowNewRecipientModal] = useState<boolean>(false);
  const [showManageTagsModal, setShowManageTagsModal] = useState<boolean>(false);
  const [showDeleteRecipientModal, setShowDeleteRecipientModal] = useState<boolean>(false);
  const [showEnrichModal, setShowEnrichModal] = useState<EnrichmentType>();
  const [showCreateOrderModal, setShowCreateOrderModal] = useState<boolean>(false);
  const [recipientToManage, setRecipientToManage] = useState<IProfileRecipient>(null);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [anchorFilterEnrichables, setAnchorFilterEnrichables] = useState<HTMLElement>();
  const [anchorEnrich, setAnchorEnrich] = useState<HTMLElement>();

  const { data, isPending } = useQuery({
    queryKey: ['profileRecipients', searchParams],
    queryFn: () => getProfileRecipientsServerPagination(...Object.values(searchParams)),
    refetchOnWindowFocus: false,
  });

  const rows: IProfileRecipient[] = data?.payload || [];
  const totalRowCount: number = data?.totalCount;

  const getProfileQuery = useQuery({
    queryKey: ['profile'],
    queryFn: () => getProfile(),
    refetchOnWindowFocus: false,
  });

  const generateCsvMutation = useMutation({
    mutationFn: () => generateRecipientsCsv(searchParams, selectedRecipients, selectedAll),
    onSuccess: (blob) => {
      // Expecting a Blob here
      // Create a URL for the Blob
      const blobURL = window.URL.createObjectURL(blob);

      // Create an anchor (`<a>`) element
      const downloadLink = document.createElement('a');
      downloadLink.href = blobURL;
      downloadLink.setAttribute('download', `Recipients_${new Date().toISOString()}.csv`); // Name the file

      // Append the link to the body, click it, and then remove it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  });

  const deleteProfileRecipientsMutation = useMutation({
    mutationFn: () => deleteProfileRecipients(selectedRecipients, searchParams, selectedAll),
    onMutate: () => setShowLoader(true),
    onSuccess: () => {
      setShowLoader(false);
      setSelectedRecipients([]);
      setShowDeleteRecipientModal(false);
      queryClient.invalidateQueries({ queryKey: ['profileRecipients'] });
    },
  });

  const columns: GridColDef[] = [
    {
      type: 'actions',
      field: 'actions',
      align: 'center',
      width: 55,
      resizable: false,
      disableReorder: true,
      hideable: false,
      getActions: (params) => [
        <Tooltip title={'Edit'} arrow={false}>
          <IconButton
            onClick={() => {
              setRecipientToManage(params.row);
              setShowNewRecipientModal(true);
            }}
          >
            <EditPencilIcon fontSize={'medium'} htmlColor={'#646367'} />
          </IconButton>
        </Tooltip>,
      ],
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      width: 150,
      editable: true,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      width: 150,
      editable: true,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 150,
      editable: true,
    },
    {
      headerName: 'To Organization',
      field: 'toOrganization',
      width: 180,
      editable: true,
    },
    {
      headerName: 'Address 1',
      field: 'address1',
      flex: 1,
      minWidth: 240,
      maxWidth: 400,
      editable: true,
    },
    {
      headerName: 'Address 2',
      field: 'address2',
      width: 180,
      editable: true,
    },
    {
      headerName: 'City',
      field: 'city',
      width: 110,
      editable: true,
    },
    {
      headerName: 'State',
      field: 'state',
      width: 68,
      align: 'center',
      editable: true,
    },
    {
      headerName: 'Zip Code',
      field: 'zipCode',
      width: 92,
      align: 'center',
      editable: true,
    },
    {
      headerName: 'Tags',
      field: 'tags',
      flex: 1,
      minWidth: 260,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return null;
        const tags: string[] = params.value.split(',');
        const mappedTags = tags.map((tag, index) => <RecipientTag name={tag} key={index} />);
        return (
          <Tooltip arrow={false} title={<Box>{mappedTags}</Box>}>
            <Box overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
              {mappedTags}
            </Box>
          </Tooltip>
        );
      },
    },
    // They are commented until the implementation rules are defined
    // {
    //     headerName: "Radius Mail Address",
    //     field: "radiusPinAddress",
    //     width: 220
    // },
    // {
    //     headerName: "Radius Mail Street",
    //     field: "radiusPinStreet",
    //     width: 220
    // },
    // {
    //     headerName: "Data Enrichment Status",
    //     field: "dataEnrichmentStatus",
    //     width: 250
    // },
  ];

  /*useEffect(() => {
      if(profileConfigurationQuery.isLoading){
          return
      }
      if(profileConfigurationQuery.data?.payload){
          setGridConfiguration(profileConfigurationQuery.data.payload)
          setInitialState(JSON.parse(profileConfigurationQuery.data.payload.configurationValue) as GridInitialState)
      }
      else{
          setInitialState({
              pagination: {
                  paginationModel: {
                      pageSize: 20,
                  }
              },
              columns: {
                  columnVisibilityModel: {
                      email: false,
                      radiusPinAddress: false,
                      radiusPinStreet: false,
                      dataEnrichmentStatus: false
                  }
              }
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileConfigurationQuery.isLoading]);*/

  /*function isRecipientBeingEnriched(recipient: IProfileRecipient) {
      let addressEnrichmentStatus = recipient.addressEnrichmentStatus
      let emailEnrichmentStatus = recipient.emailEnrichmentStatus
      let nameCityEnrichmentStatus = recipient.nameCityEnrichmentStatus
      let demographyEnrichmentStatus = recipient.demographyEnrichmentStatus
      return addressEnrichmentStatus === DataEnrichmentStatus.InProgress || emailEnrichmentStatus === DataEnrichmentStatus.InProgress || nameCityEnrichmentStatus === DataEnrichmentStatus.InProgress || demographyEnrichmentStatus === DataEnrichmentStatus.InProgress
  }
  function isRecipientEnriched(recipient: IProfileRecipient) {
      let addressEnrichmentStatus = recipient.addressEnrichmentStatus
      let emailEnrichmentStatus = recipient.emailEnrichmentStatus
      let nameCityEnrichmentStatus = recipient.nameCityEnrichmentStatus
      let demographyEnrichmentStatus = recipient.demographyEnrichmentStatus
      return addressEnrichmentStatus === DataEnrichmentStatus.Complete || emailEnrichmentStatus === DataEnrichmentStatus.Complete || nameCityEnrichmentStatus === DataEnrichmentStatus.Complete || demographyEnrichmentStatus === DataEnrichmentStatus.Complete
  }*/

  /*function updateColumnConfiguration(){
      let state = apiRef.current.exportState()
      let newConfig
      if(gridConfiguration){
          newConfig = {...gridConfiguration, configurationValue: JSON.stringify(state)}
      }
      else{
          newConfig = {id: 0, configurationType: ProfileConfigurationType.AddressBookColumns, configurationValue: JSON.stringify(state)}
      }
      postProfileConfiguration(newConfig)
  }*/

  const handleEnrichFilter = (option: string) => {
    setSearchParams((prev) => ({
      ...prev,
      filters: searchParams.filters.includes(option) ? '' : `field=enrich&operator=equals&value=${option}`,
    }));
  };

  const renderToolbarFilterLeft = () => (
    <>
      <Grid item>
        <Typography variant="h4" textAlign={'center'}>
          Upload
        </Typography>
        <Box display="flex" gap={1} mt={1}>
          <Tooltip title={'Add Recipient'}>
            <MDIconButton onClick={() => setShowNewRecipientModal(true)}>
              <PlusIcon />
            </MDIconButton>
          </Tooltip>
          {!!getProfileQuery.data?.id && (
            <CSVBoxButton
              licenseKey={CSVBOX_LICENCE}
              user={{ user_id: 'LettrLabsCsvBoxAdmin', profile_id: getProfileQuery.data.id }}
              onImport={(result: boolean) => {
                if (result) queryClient.invalidateQueries({ queryKey: ['profileRecipients'] });
                else alert('Upload failed. Please try again.');
              }}
              render={(launch: MouseEventHandler<HTMLButtonElement>) => (
                <Tooltip title={'Import Recipients'}>
                  <MDIconButton onClick={launch}>
                    <ImportIcon />
                  </MDIconButton>
                </Tooltip>
              )}
            />
          )}
        </Box>
      </Grid>
      <Grid item>
        <Divider light orientation="vertical" />
      </Grid>
      <Grid item>
        <Typography variant="h4" textAlign={'center'}>
          Tags
        </Typography>
        <Tooltip title={'Add Tags to Recipients'}>
          <Box mt={1}>
            <MDIconButton disabled={!selectedRecipients.length} onClick={() => setShowEditTagsModal(true)}>
              <AddTagToRecipientIcon />
            </MDIconButton>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Divider light orientation="vertical" />
      </Grid>
      <Grid item>
        <Typography variant="h4" textAlign={'center'}>
          Actions
        </Typography>
        <Box display="flex" gap={1} mt={1}>
          <MDButton disabled={!selectedRecipients.length} onClick={() => setShowCreateOrderModal(true)}>
            Create Order
          </MDButton>
          {/* 
            This is the recipient enrichment flow. 
            It's not added yet because there's no figma designs for it
            But all functionality works
            <Tooltip title={"Enrich Recipients"}>
              <MDIconButton><WandIcon/></MDIconButton>
            </Tooltip> */}
        </Box>
      </Grid>
      <Grid item>
        <Divider light orientation="vertical" />
      </Grid>
    </>
  );
  const renderToolbarFilterRight = () => (
    <>
      <Grid item alignContent={'flex-end'}>
        <Tooltip title="Data enrichment adds external details to existing data, improving accuracy and completeness">
          <MDButton color="white" onClick={(evt) => setAnchorFilterEnrichables(evt.currentTarget)}>
            <AutoAwesomeOutlined sx={{ mr: 1 }} />
            Show Enrichables
          </MDButton>
        </Tooltip>

        <Menu
          anchorEl={anchorFilterEnrichables}
          open={!!anchorFilterEnrichables}
          onClose={() => setAnchorFilterEnrichables(null)}
        >
          <MenuItem onClick={() => handleEnrichFilter('address')} sx={{ width: 180 }}>
            Name & Address
            {searchParams.filters.includes('address') && <AutoFixOff sx={{ ml: 'auto', height: 18 }} />}
          </MenuItem>
          <MenuItem onClick={() => handleEnrichFilter('birthday')} sx={{ width: 180 }}>
            Birthday
            {searchParams.filters.includes('birthday') && <AutoFixOff sx={{ ml: 'auto', height: 18 }} />}
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item>
        <Divider light orientation="vertical" />
      </Grid>
      <Grid item alignContent={'flex-end'}>
        <Box display="flex" gap={1}>
          <Tooltip title={'Download Recipients'}>
            <Box>
              <MDIconButton disabled={!rows?.length} onClick={() => generateCsvMutation.mutate()}>
                <DownloadIcon />
              </MDIconButton>
            </Box>
          </Tooltip>
          <Tooltip title={'Edit Recipients'}>
            <Box>
              <MDIconButton
                disabled={!selectedRecipients.length}
                onClick={() => setShowBulkUpdateRecipientsModal(true)}
              >
                <EditPencilIcon />
              </MDIconButton>
            </Box>
          </Tooltip>
          <Tooltip title={'Delete Recipients'}>
            <MDIconButton disabled={!selectedRecipients.length} onClick={() => setShowDeleteRecipientModal(true)}>
              <DeleteIcon />
            </MDIconButton>
          </Tooltip>
          <Tooltip title={'Enrich Recipients'}>
            <MDIconButton disabled={!selectedRecipients.length} onClick={(evt) => setAnchorEnrich(evt.currentTarget)}>
              <AutoFixHigh />
            </MDIconButton>
          </Tooltip>
          <Menu anchorEl={anchorEnrich} open={!!anchorEnrich} onClose={() => setAnchorEnrich(null)}>
            <MenuItem onClick={() => setShowEnrichModal(EnrichmentType.Address)} sx={{ width: 180 }}>
              Name & Address
            </MenuItem>
            <MenuItem onClick={() => setShowEnrichModal(EnrichmentType.Birthday)} sx={{ width: 180 }}>
              Birthday
            </MenuItem>
          </Menu>
        </Box>
      </Grid>
    </>
  );

  return (
    <MDCard sx={{ p: 2 }}>
      <Table<IProfileRecipient>
        serverDriven
        toolbarWithTitle
        rows={rows}
        columns={columns}
        totalRowCount={totalRowCount}
        isLoading={isPending}
        tableHeight={'70vh'}
        allSelected={selectedAll}
        setAllSelected={setSelectedAll}
        setSearchParams={setSearchParams}
        selectedRows={selectedRecipients}
        setSelectedRows={setSelectedRecipients}
        renderToolbarFilterLeft={renderToolbarFilterLeft}
        renderToolbarFilterRight={renderToolbarFilterRight}
        processRowUpdate={(newRow) => {
          setShowLoader(true);
          postProfileRecipient(newRow).then(() => {
            setShowLoader(false);
            queryClient.invalidateQueries({ queryKey: ['profileRecipients'] });
          });
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              toOrganization: false,
              email: false,
              radiusPinAddress: false,
              radiusPinStreet: false,
              dataEnrichmentStatus: false,
            },
          },
        }}
      />

      <CreateOrderFlow
        show={showCreateOrderModal}
        setShow={setShowCreateOrderModal}
        recipientIds={selectedRecipients}
        lastSearchParams={searchParams}
        selectedAll={selectedAll}
      />

      <GroupRecipientsByTagsModal
        show={showEditTagsModal}
        setShow={setShowEditTagsModal}
        selectedRecipients={selectedRecipients}
        showManageTagsModal={showManageTagsModal}
        setShowManageTagsModal={setShowManageTagsModal}
        lastSearchParams={searchParams}
        totalCount={totalRowCount}
        selectedAll={selectedAll}
      />

      <ManageTagsModal show={showManageTagsModal} setShow={setShowManageTagsModal} />

      <BulkUpdateRecipientsModal
        show={showBulkUpdateRecipientsModal}
        setShow={setShowBulkUpdateRecipientsModal}
        selectedRecipients={selectedRecipients}
        lastSearchParams={searchParams}
        totalCount={totalRowCount}
      />

      <AddNewRecipientModal
        show={showNewRecipientModal}
        setShow={setShowNewRecipientModal}
        recipientToManage={recipientToManage}
        setRecipientToManage={setRecipientToManage}
      />

      <InfoModal
        showCancelButton
        showConfirmButton
        show={showDeleteRecipientModal}
        setShow={setShowDeleteRecipientModal}
        headerText={`Are you sure you want to delete ${formatNumber(selectedRecipients.length)} recipient${selectedRecipients.length > 1 ? 's' : ''
          }? (This cannot be undone)`}
        confirmButtonOnClick={() => deleteProfileRecipientsMutation.mutate()}
        cancelButtonOnClick={() => setShowDeleteRecipientModal(false)}
      />

      <EnrichModal
        show={!!showEnrichModal}
        setShow={() => setShowEnrichModal(undefined)}
        lastSearchParams={searchParams}
        selectedRecipients={selectedRecipients}
        enrichBy={showEnrichModal}
        selectedAll={selectedAll}
      />

      {/*<DeleteRecipientConfirmationModal
        show={showDeleteRecipientModal}
        setShow={setShowDeleteRecipientModal}
        recipients={selectedRecipients}
        getRecipients={getRecipients}
      />*/}

      {/*<CreateOrderFromRecipientSearchModal
        show={showCreateOrderModal}
        setShow={setShowCreateOrderModal}
        selectedOrderType={selectedOrderType}
        setSelectedOrderType={setSelectedOrderType}
        setShowOrderNameModal={setShowOrderNameModal}
        setShowTemplatesModal={setShowTemplatesModal}
      />*/}

      {/*<TemplatesModal
        show={showTemplatesModal}
        setShow={setShowTemplatesModal}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        setShowCreateOrderModal={setShowCreateOrderModal}
        showOrderNameModal={showOrderNameModal}
        setShowOrderNameModal={setShowOrderNameModal}
        selectedTemplateType={selectedTemplateType}
        setSelectedTemplateType={setSelectedTemplateType}
        selectedPremadeDesign={selectedPremadeDesign}
        setSelectedPremadeDesign={setSelectedPremadeDesign}
        setShowPremadeDesignFinalizationModal={setShowPremadeDesignFinalizationModal}
      />
      <CreateDesignOrderModal
        show={showPremadeDesignFinalizationModal}
        setShow={setShowPremadeDesignFinalizationModal}
        design={selectedPremadeDesign}
        onSave={createOrder}
        isCreateButtonDisabled={isPremadeTemplateCreateButtonDisabled}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        orderName={orderName}
        setOrderName={setOrderName}
      />
      <EnrichDataInfoModal
        show={showEnrichDataInfoModal}
        setShow={setShowEnrichDataInfoModal}
        setSelectedEnrichmentType={setSelectedEnrichmentType}
        setShowEnrichRecipientsModal={setShowEnrichRecipientsModal}
      />
      <EnrichRecipientsModal
        show={showEnrichRecipientsModal}
        setShow={setShowEnrichRecipientsModal}
        setShowEnrichDataInfoModal={setShowEnrichDataInfoModal}
        recipientsToEnrich={recipientsToEnrich}
        setRecipientsToEnrich={setRecipientsToEnrich}
        enrichmentType={selectedEnrichmentType}
        setShowCheckoutModal={setShowEnrichRecipientsCheckoutModal}
        setRecipientEnrichmentOrderId={setRecipientEnrichmentOrderId}
      />*/}

      {/*<EnrichRecipientsCheckoutModal
        show={showEnrichRecipientsCheckoutModal}
        setShow={setShowEnrichRecipientsCheckoutModal}
        recipientEnrichmentOrderId={recipientEnrichmentOrderId}
        enrichmentType={selectedEnrichmentType}
        recipientsToEnrich={recipientsToEnrich}
        setRecipients={setData}
      />*/}
    </MDCard>
  );
};

export default AddressBookPage;