/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Box from "@mui/material/Box";
import {Palette, styled, Theme} from "@mui/material";
import rgba from "material-ui/theme/functions/rgba";

export default styled(Box)(({ theme, ownerState }: { theme?: Theme; ownerState: any }) => {
  const { palette, typography, functions } = theme;
  const { color, backgroundColorOpacity } = ownerState;

  const { white } = palette;
  const { size, fontWeightMedium } = typography;
  const { pxToRem } = functions;

  // backgroundImage value
  const backgroundColorValue = rgba(palette[color as keyof Palette].main, backgroundColorOpacity)

  return {
    display: "flex",
    alignItems: "center",
    backgroundColor: backgroundColorValue,
    color: white.main,
    position: "relative",
    padding: pxToRem(12),
    borderRadius: "12px",

    fontSize: size.md,
    fontWeight: fontWeightMedium,
  };
});
