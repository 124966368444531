import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { CreativeEngine } from '@cesdk/cesdk-js';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { IFont } from 'newStandard/src/services/font/types';

import { MailMergeMapType } from '../utils/mailMergeOptions';
import { checkHasEnvelope } from '../utils/templateHelper';
import { useSaveScene } from '../hooks/useSaveScene';

interface ITextCursor {
  block: number;
  from: number;
  to: number;
}

interface EditorContextProps {
  engine?: CreativeEngine;
  setEngine: React.Dispatch<React.SetStateAction<CreativeEngine | undefined>>;
  envelopeEngine?: CreativeEngine;
  setEnvelopeEngine: React.Dispatch<React.SetStateAction<CreativeEngine | undefined>>;
  textCursor: ITextCursor;
  setTextCursor: React.Dispatch<React.SetStateAction<ITextCursor>>;
  isSceneLoading?: boolean;
  setIsSceneLoading: React.Dispatch<React.SetStateAction<boolean>>;
  lettrLabsPanel?: boolean;
  setLettrLabsPanel: React.Dispatch<React.SetStateAction<boolean>>;
  returnAddressPanel?: boolean;
  setReturnAddressPanel: React.Dispatch<React.SetStateAction<boolean>>;
  mailMergeFields?: MailMergeMapType;
  setMailMergeFields: React.Dispatch<React.SetStateAction<MailMergeMapType>>;
  pages: number[];
  setPages: React.Dispatch<React.SetStateAction<number[]>>;
  selectedFont?: IFont;
  setSelectedFont: React.Dispatch<React.SetStateAction<IFont | undefined>>;
  saveCurrentScene: () => void;
  showCardRear?: boolean;
  setShowCardRear: (show: boolean) => void;
}

const EditorContextDefault: EditorContextProps = {
  setEngine: () => {},
  setEnvelopeEngine: () => {},
  textCursor: { block: 0, from: 0, to: 0 },
  setTextCursor: () => {},
  setIsSceneLoading: () => {},
  setLettrLabsPanel: () => {},
  setReturnAddressPanel: () => {},
  setMailMergeFields: () => {},
  pages: [],
  setPages: () => {},
  setSelectedFont: () => {},
  saveCurrentScene: () => {},
  setShowCardRear: () => {},
};

const EditorContext = createContext<EditorContextProps>(EditorContextDefault);
EditorContext.displayName = 'EditorContext';

interface EditorContextProviderProps {
  children: ReactNode;
}

export function EditorContextProvider({ children }: EditorContextProviderProps) {
  const { template, setSaveSteps } = useWorkflowContext();

  const [engine, setEngine] = useState<CreativeEngine>();
  const [envelopeEngine, setEnvelopeEngine] = useState<CreativeEngine>();
  const [selectedFont, setSelectedFont] = useState<IFont>();
  const [showCardRear, setShowCardRear] = useState<boolean>(false);
  const [isSceneLoading, setIsSceneLoading] = useState<boolean>(false);
  const [lettrLabsPanel, setLettrLabsPanel] = useState<boolean>(false);
  const [returnAddressPanel, setReturnAddressPanel] = useState<boolean>(false);
  const [mailMergeFields, setMailMergeFields] = useState<MailMergeMapType>();
  const [textCursor, setTextCursor] = useState<ITextCursor>({ block: 0, from: 0, to: 0 });
  const [pages, setPages] = useState<number[]>([]);

  const { saveCurrentScene } = useSaveScene(engine, envelopeEngine, mailMergeFields);

  useEffect(() => {
    if (engine?.scene?.getCurrentPage() && !isSceneLoading) setLettrLabsPanel(true);
  }, [engine?.scene, isSceneLoading]);

  useEffect(() => {
    if (!engine || (checkHasEnvelope(template.product) && !envelopeEngine)) return;
    setSaveSteps((prev) => ({ ...prev, 2: saveCurrentScene }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engine, envelopeEngine, setSaveSteps, template.product]);

  return (
    <EditorContext.Provider
      value={{
        engine,
        setEngine,
        envelopeEngine,
        setEnvelopeEngine,
        textCursor,
        setTextCursor,
        isSceneLoading,
        setIsSceneLoading,
        lettrLabsPanel,
        setLettrLabsPanel,
        returnAddressPanel,
        setReturnAddressPanel,
        mailMergeFields,
        setMailMergeFields,
        pages,
        setPages,
        selectedFont,
        setSelectedFont,
        saveCurrentScene,
        showCardRear,
        setShowCardRear,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorContext() {
  const context = useContext(EditorContext);
  if (context !== undefined) return context;
  throw new Error('useEditorContext must be used within a EditorContextProvider');
}
