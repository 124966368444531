import { useEffect, useState } from 'react';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Edit } from '@mui/icons-material';
import { ValidationError } from 'yup';

import { ITemplateOrderIntersection, useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { States } from 'newStandard/src/components/AddressFormikFields/statesEnum';
import { addressToReturnSchema } from 'models/modelValidations/returnAddress';
import IncrementDecrementButton from 'components/increment-decrement-button';
import ProfileReturnAddress from 'services/profile-return-address';
import FormikErrorMessage from 'components/formik-error-message';
import ReturnAddressModal from './ReturnAddressModal';
import { ReturnAddress } from 'models/returnAddress';
import { OrderHelper } from 'helpers/OrderHelper';

import useReturnAddressUpdate from '../../hooks/useReturnAddressUpdate';
import { checkIsPrintedCard } from '../../utils/templateHelper';

const EMPTY_RETURN_ADDRESS = { id: 0, name: '', address1: '', city: '', state: '', zip: '' };

const ADDRESS_FIELDS = [
  { name: 'returnFirstName', label: 'First Name' },
  { name: 'returnLastName', label: 'Last Name' },
  { name: 'returnOrganization', label: 'Organization' },
  { name: 'returnAddress1', label: 'Address 1' },
  { name: 'returnAddress2', label: 'Address 2' },
  { name: 'returnCity', label: 'City' },
];

export default function ReturnAddressPanel() {
  useReturnAddressUpdate();
  const { template, setTemplate } = useWorkflowContext();
  const { getProfileReturnAddresses } = ProfileReturnAddress();
  const [selectedReturnAddress, setSelectedReturnAddress] = useState<ReturnAddress>();
  const [returnAddressToManage, setReturnAddressToManage] = useState<ReturnAddress>();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { data: { payload: addresses } = {} } = useQuery({
    queryKey: ['returnAddresses'],
    queryFn: getProfileReturnAddresses,
  });

  useEffect(() => {
    if (!selectedReturnAddress) return;
    setTemplate((prev) => ({
      ...prev,
      returnFirstName: selectedReturnAddress?.firstName || '',
      returnLastName: selectedReturnAddress?.lastName || '',
      returnOrganization: selectedReturnAddress?.toOrganization || '',
      returnCity: selectedReturnAddress?.city || '',
      returnState: selectedReturnAddress?.state || '',
      returnZip: selectedReturnAddress?.zip || '',
      returnAddress1: selectedReturnAddress?.address1 || '',
      returnAddress2: selectedReturnAddress?.address2 || '',
    }));
  }, [selectedReturnAddress, setTemplate]);

  useEffect(() => {
    if (!addresses?.length || template.returnAddress1) return;
    const defaultReturnAddress = addresses.find((address) => address.isDefault);
    setSelectedReturnAddress(defaultReturnAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  const handleFieldChange = async (field: string, value: string) => {
    setSelectedReturnAddress(undefined);
    setTemplate((prev) => ({ ...prev, [field]: value }));
    try {
      await addressToReturnSchema.validateAt(field, { ...template, [field]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    } catch (validationError: unknown) {
      const { message } = validationError as ValidationError;
      setErrors((prevErrors) => ({ ...prevErrors, [field]: message }));
    }
  };

  const renderAdornment = (type: 'minus' | 'plus') => (
    <IncrementDecrementButton
      type={type}
      value={template.fontSizeReturnAddress}
      minValue={OrderHelper.getMinFontsSizeForReturnAddress(template.product)}
      maxValue={OrderHelper.getMaxFontsSizeForReturnAddress(template.product)}
      setValue={(newValue) => setTemplate((prev) => ({ ...prev, fontSizeReturnAddress: newValue }))}
    />
  );

  return (
    <Stack p={2} maxWidth={432} width={'100%'} spacing={2}>
      <Box display="flex" alignItems={'center'} gap={1} mt={2}>
        <Select value={selectedReturnAddress?.name ?? 'Custom'} renderValue={(value) => value}>
          <MenuItem value={'New'} onClick={() => setReturnAddressToManage(EMPTY_RETURN_ADDRESS)}>
            Add New
          </MenuItem>
          <Divider light />
          <MenuItem value={'Custom'} onClick={() => setSelectedReturnAddress(EMPTY_RETURN_ADDRESS)}>
            Custom
          </MenuItem>
          {addresses?.map((address: ReturnAddress) => (
            <MenuItem
              key={address.id}
              value={address.id}
              onClick={() => setSelectedReturnAddress(address)}
              sx={{ justifyContent: 'space-between' }}
            >
              {address.name}
              <Tooltip title={'Edit Address'}>
                <Edit onClick={() => setReturnAddressToManage(address)} />
              </Tooltip>
            </MenuItem>
          ))}
        </Select>
        {!checkIsPrintedCard(template.product) && (
          <TextField
            value={template.fontSizeReturnAddress}
            InputProps={{ startAdornment: renderAdornment('minus'), endAdornment: renderAdornment('plus') }}
            inputProps={{ disabled: true, sx: { textAlign: 'center', paddingRight: 0 } }}
          />
        )}
      </Box>
      <Divider light />

      {ADDRESS_FIELDS.map((field) => (
        <Box key={field.name}>
          <TextField
            fullWidth
            name={field.name}
            label={field.label}
            value={template[field.name as keyof ITemplateOrderIntersection] || ''}
            onChange={(e) => handleFieldChange(field.name, e.target.value)}
            error={!!errors[field.name]}
          />
          <FormikErrorMessage meta={{ touched: true, error: errors[field.name] }} />
        </Box>
      ))}
      <Box display={'flex'} gap={1}>
        <Box flex={1}>
          <FormControl fullWidth>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              displayEmpty
              label="State"
              labelId="state-label"
              name="returnState"
              value={template.returnState || ''}
              onChange={(evt) => handleFieldChange('returnState', evt.target.value)}
              error={!!errors.returnState}
            >
              {Object.keys(States).map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            <FormikErrorMessage meta={{ touched: true, error: errors.returnState }} />
          </FormControl>
        </Box>
        <Box flex={1}>
          <TextField
            name="returnZip"
            label={'Zip'}
            value={template.returnZip || ''}
            onChange={(e) => handleFieldChange('returnZip', e.target.value)}
            error={!!errors.returnZip}
          />
          <FormikErrorMessage meta={{ touched: true, error: errors.returnZip }} />
        </Box>
      </Box>
      <ReturnAddressModal
        returnAddress={returnAddressToManage}
        isFirst={!!(!addresses?.length || (returnAddressToManage?.isDefault && addresses?.length === 1))}
        onClose={(address) => {
          setSelectedReturnAddress(address);
          setReturnAddressToManage(undefined);
        }}
      />
    </Stack>
  );
}
