import { Children, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Step, StepLabel, Stepper as MuiStepper, Collapse, useTheme, useMediaQuery } from '@mui/material';

import MDCard from 'material-ui/components/MDCard';

interface StepperProps {
  steps: string[];
  children: ReactNode;
  maxStepAllowed?: number;
  changeStep: (step: number) => void;
}

export default function Stepper({ children, steps, maxStepAllowed = steps.length, changeStep }: StepperProps) {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const step = parseInt(queryParams.get('step') ?? '1');

  const handleStepClick = (newStep: number) => {
    if (newStep <= maxStepAllowed && newStep !== step) changeStep(newStep);
  };

  return (
    <>
      <MDCard color={'white'} borderRadiusSize={'xxxl'} boxShadow={false} sx={{ py: 1, mb: 1 }}>
        <MuiStepper activeStep={step - 1} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} disabled={index + 1 > maxStepAllowed}>
              <StepLabel
                onClick={() => handleStepClick(index + 1)}
                sx={{
                  '.MuiStepLabel-label': isXl ? undefined : { fontSize: 12, mt: '0!important' },
                  '.MuiStepLabel-iconContainer svg': {
                    height: isXl ? undefined : 24,
                    cursor: index + 1 > maxStepAllowed ? 'default' : 'pointer',
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </MuiStepper>
      </MDCard>

      {Children.map(children, (child, index) => (
        <Collapse key={index + 1} in={step === index + 1}>
          {child}
        </Collapse>
      ))}
    </>
  );
}
