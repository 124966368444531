import { Stack, Typography } from '@mui/material';

import { useWorkflowContext } from 'newStandard/src/contexts/useWorkflowContext';
import { ProductOptions, YesNo } from 'newStandard/src/services/order/types';
import MDButton from 'material-ui/components/MDButton';

import { useEditorContext } from '../../contexts/useEditorContext';
import { checkHasFrontLogo, checkIsPrintedCard } from '../../utils/templateHelper';
import { BlockNames, DefaultArtifacts } from '../../utils/sceneEnums';
import { createImage } from '../../utils/imageHelper';
import { useEffect, useState } from 'react';

export default function CardFormatting() {
  const { engine } = useEditorContext();
  const { template, setTemplate } = useWorkflowContext();
  const [, setIsLogoSizeUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!engine?.event || checkIsPrintedCard(template.product)) return;
    const getImageDimensions = (uri: string): Promise<{ width: number; height: number }> => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.src = uri;
      });
    };

    const getImageWidth = async (uri: string, targetHeight: number): Promise<number> => {
      const { width, height } = await getImageDimensions(uri);
      const aspectRatio = width / height;
      const newWidth = targetHeight * aspectRatio;
      return newWidth;
    };

    const unsubscribe = engine.event.subscribe([], () => {
      const updateLogoSize = async (block: number, uri: string) => {
        const positionX = engine.block.getPositionX(block);
        const positionY = engine.block.getPositionY(block);
        const height = engine.block.getHeight(block);
        const width = await getImageWidth(uri, height);
        const parent = engine.block.getParent(block);
        engine.block.destroy(block);
        createImage(engine, { name: BlockNames.FrontLogo, height, width, positionX, positionY, parent, uri });
      };

      setIsLogoSizeUpdated((prev) => {
        const frontLogo = engine.block.findByName(BlockNames.FrontLogo)[0];
        const fill = frontLogo ? engine.block.getFill(frontLogo) : null;
        const uri = fill ? engine.block.getString(fill, 'fill/image/imageFileURI') : null;
        if (uri === DefaultArtifacts.Front || !frontLogo) return false;
        if (prev) return prev;
        updateLogoSize(frontLogo, uri);
        return true;
      });
    });

    return () => {
      unsubscribe();
    };
  }, [engine, template.product]);

  const handleLogoToggle = () => {
    const show = template.noFrontLogo === YesNo.Yes;
    setTemplate((prev) => ({ ...prev, noFrontLogo: show ? YesNo.No : YesNo.Yes }));
    const frontLogos = engine.block.findByName(BlockNames.FrontLogo);
    if (!frontLogos.length && show) {
      const isPostCard = template.product === ProductOptions.HandwrittenPostCardA8;
      createImage(engine, {
        name: BlockNames.FrontLogo,
        height: isPostCard ? 0.800000011920929 : 0.8500000238418579,
        width: isPostCard ? 3 : 6.5,
        positionX: isPostCard ? 2.4000000953674316 : 0.5,
        positionY: isPostCard ? 0.15000000596046448 : 0.15000000596046448,
        parent: engine.scene.getPages()[0],
        uri: DefaultArtifacts.Front,
      });
    } else {
      frontLogos.forEach((block) => {
        engine.block.setScopeEnabled(block, 'layer/visibility', true);
        engine.block.setVisible(block, show);
      });
    }

    if (template.product !== ProductOptions.LargeHandwrittenCardA8) return;
    engine.block.findByName(BlockNames.HandwrittenText).forEach((block) => {
      engine.block.setScopeEnabled(block, 'layer/move', true);
      engine.block.setScopeEnabled(block, 'layer/resize', true);
      engine.block.setPositionY(block, show ? 1 : 0.35);
      engine.block.setHeight(block, show ? 4.2 : 4.85);
      engine.block.setScopeEnabled(block, 'layer/move', false);
      engine.block.setScopeEnabled(block, 'layer/resize', false);
    });
  };

  if (!checkHasFrontLogo(template.product)) return null;
  return (
    <Stack spacing={1}>
      <Typography variant="body2" fontWeight={'bold'}>
        Card Formatting
      </Typography>
      <MDButton fullWidth size={'medium'} color={'light'} onClick={handleLogoToggle}>
        {template.noFrontLogo === 'Yes' ? 'Add Logo' : 'Remove Logo'}
      </MDButton>
    </Stack>
  );
}
