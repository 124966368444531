import { ReactNode, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { Alert, Dialog, DialogContent, Divider, Grid, Typography } from '@mui/material';

import MDButton from 'material-ui/components/MDButton';
import { ICheckout } from 'models/checkout';

import StripeCheckoutButton from './StripeCheckoutButton';
import SelectPaymentMethod from './SelectPaymentMethod';
import CheckoutSummary from './CheckoutSummary';
import CheckoutWrapper from './CheckoutWrapper';
import CheckoutButton from './CheckoutButton';

interface CheckoutModalProps {
  show: boolean;
  children?: ReactNode;
  checkout?: ICheckout;
  isLoading: boolean;
  isPending: boolean;
  selectedCard: PaymentMethod;
  setSelectedCard: (selectedCard: PaymentMethod) => void;
  onCheckout: () => void;
  onClose: () => void;
}

export default function CheckoutModal({
  show,
  children = null,
  checkout,
  isLoading,
  isPending,
  selectedCard,
  setSelectedCard,
  onCheckout,
  onClose,
}: CheckoutModalProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasIncompleteCard, setHasIncompleteCard] = useState<boolean>(true);

  return (
    <Dialog open={show} onClose={() => onClose()} fullWidth>
      <DialogContent>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage('')} sx={{ mb: 1 }}>
            {errorMessage}
          </Alert>
        )}
        <CheckoutWrapper checkout={checkout} isLoading={isLoading}>
          <Typography variant="h4" mb={1}>
            Summary
          </Typography>
          {children}
          <CheckoutSummary checkout={checkout} isLoading={isLoading} />
          <Divider />
          {!!checkout?.balance && (
            <>
              <Typography variant="h4" fontSize={18} mb={1}>
                Payment Method
              </Typography>
              <SelectPaymentMethod
                checkout={checkout}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setHasIncompleteCard={setHasIncompleteCard}
              />
            </>
          )}
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm="auto">
              <MDButton fullWidth color="light" onClick={onClose}>
                Cancel
              </MDButton>
            </Grid>
            <Grid item xs>
              {!!checkout?.stripeToken ? (
                <StripeCheckoutButton
                  checkout={checkout}
                  isDisabled={isPending}
                  onCheckout={onCheckout}
                  selectedCard={selectedCard}
                  setErrorMessage={setErrorMessage}
                />
              ) : (
                  <CheckoutButton
                    balance={checkout?.balance}
                    isPending={isPending}
                    selectedCard={selectedCard}
                    hasIncompleteCard={hasIncompleteCard}
                    onCheckout={onCheckout}
                  />
              )}
            </Grid>
          </Grid>
        </CheckoutWrapper>
      </DialogContent>
    </Dialog>
  );
}
